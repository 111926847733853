import { inject } from '../../../../../../common/container/inject';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { saveFileInBrowser } from '../../../../../../common/utils/saveFileInBrowser';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ILocalization, ILocalizationToken } from '../../../../application/language/ILocalization';
import { ILearningRootService, LearningRootServiceToken } from '../../../../service/LearningRootService';

export class LearningReportDomain {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    public anchorEl = injectPrimitive<HTMLButtonElement | null>(null),
    public isCanDownload = injectPrimitive<boolean>(false),
    public i18n: ILocalization = inject<ILocalization>(ILocalizationToken),
    private learningRootService = inject<ILearningRootService>(LearningRootServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
  ) { }

  boot = async () => {
    const isCanDownload = this.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
    this.isCanDownload.setValue(isCanDownload);
  };

  handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    this.anchorEl.setValue(event.currentTarget);
  };
  handleClose = () => {
    this.anchorEl.setValue(null);
  };

  genereteDocx = async (unitSummuryTemplatePath?: string) => {
    const userList = (await this.rootService.user.entity.search({ limit: 1000000 })).data; ///, fields: ['id', 'displayName', 'systemRoleId']
    const systemRoleList = (await this.rootService.systemRole.entity.search({ limit: 1000000, fields: ['id', 'name'] }))
      .data;
    const projects = (
      await this.rootService.project.entity.search({
        limit: 100000,
        fields: ['id', 'name', 'rolesMap'],
      })
    ).data;
    const blobDocx = await this.learningRootService.report.generateProjectUnitSummuryByUsers(
      userList,
      systemRoleList,
      projects,
      unitSummuryTemplatePath,
    );

    this.saveFile(this.i18n.translate('learningAdminPage.trainingReportDOCXFileLabel'), blobDocx);
  };

  generatePdf = async (unitSummuryTemplatePath?: string) => {
    const userList = (await this.rootService.user.entity.search({ limit: 1000000 })).data; //, fields: ['id', 'displayName', 'systemRoleId']
    const systemRoleList = (await this.rootService.systemRole.entity.search({ limit: 1000000, fields: ['id', 'name'] }))
      .data;
    const projects = (
      await this.rootService.project.entity.search({
        limit: 100000,
        fields: ['id', 'name', 'rolesMap'],
      })
    ).data;
    const blobPdf = await this.learningRootService.report.generateProjectUnitSummuryByUsersPdf(
      userList,
      systemRoleList,
      projects,
      unitSummuryTemplatePath,
    );

    this.saveFile(this.i18n.translate('learningAdminPage.trainingReportPDFFileLabel'), blobPdf);
  };

  saveFile(filename: string, blob: any) {
    saveFileInBrowser(filename, blob)
  }

  handleReportDocx = (unitSummuryTemplatePath?: string) => {
    this.genereteDocx(unitSummuryTemplatePath);
    this.handleClose();
  };

  handleReportPdf = (unitSummuryTemplatePath?: string) => {
    this.generatePdf(unitSummuryTemplatePath);
    this.handleClose();
  };
}
