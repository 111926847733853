import wretch from 'wretch';

import { CTFCoreRestCRUDService } from '../common/CTFCoreRestCRUDService';
import { IChallenge } from './interface/IChallenge';
import { IRestCTFStatisticService } from './IRestCTFStatisticService';

wretch().errorType('json');

export class RestCTFStatisticService extends CTFCoreRestCRUDService<IChallenge> implements IRestCTFStatisticService {
  getEndpoint(): string {
    const url = this.getBaseAPIUrl()
    return `${url}`;
  }

  async getUserCount() {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}users/count`)
      .get()
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  async getMostAndLessPopularChallenges() {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}challenges/mostAndLessPopular`)
      .get()
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text()

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }


  async getCorrectIncorrect () {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}statistic/correctIncorrect`)
      .get()
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text()

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }

  async getSolvedChallengesByCategoryService () {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}statistic/category/solves`)
      .get()
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text()

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }
}
