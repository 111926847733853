import { Checkbox, FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useI18n } from '../../../../../../../application/language/useI18n';
import { FormModalComponent } from '../../../../../../design/dataTable/form/view/FormModalComponent';
import { AutocompleteSelectItemType, FormMultiAutocomplete } from '../../../../../../design/form/FormMultiAutocomplete';
import { FormTextArea } from '../../../../../../design/form/FormTextArea';
import { FormTextField } from '../../../../../../design/form/FormTextField';
import { SpecificationCategoryModalDomain } from './SpecificationCategoryModalDomain';
import { SpecificationCategoryType } from '../../../../../../../service/specification/category/ISpecificationCategoryModelConditionMap';
import { ConfigManager } from '../../../../../../../application/config/ConfigManager';

export interface ISpecificationEntityAdminTableProperties {
  domain: SpecificationCategoryModalDomain;
  dataCy?: string;
}

export const SpecificationCategoryModal = observer(({ domain, dataCy }: ISpecificationEntityAdminTableProperties) => {
  const { translate } = useI18n();

  const [isChecked, setChecked] = useState(domain.ui.model.entity.isActive);
  const [isEdit] = useState(!!domain.ui.model.entity.id)
  const [buttonText, setButtonText] = useState(translate('buttonsText.addButton') || 'ДОБАВИТЬ')
  const saveButton = translate('buttonsText.saveButton')
  const addButton = translate('buttonsText.addButton')
  const modalTitle = !isEdit
    ? translate('buttonsText.newCategory') : domain.ui.model.entity.categoryType === SpecificationCategoryType.specificationCategoryValue
      ? translate('phrases.edithCategoryWithValuePick')  : translate('phrases.edithCategoryWithFieldText')

  useEffect(() => {
    setButtonText(isEdit ? saveButton : addButton)
    domain.layoutDomain.setPageTitle(isEdit ? `Редактировать` : `Добавить`);
    setChecked(domain.ui.model.entity.isActive);
    return (() => {
      domain.layoutDomain.setPageTitle('Категории характеристик')
    })
  }, [domain]);

  const getSpecificationsValues = () => {
    const items: any[] = [];
    for (let category of domain.ui.categories.list) {
      if (category.categoryType === SpecificationCategoryType.specificationCategoryValue) {
        const specifications = domain.ui.entities.list.filter(
          (specification) => specification.categoryId === category.id && !specification.applicationId,
        );

        items.push({
          name: translate('systemSpecificationsPage.categoryName', { name: category.name || translate('systemSpecificationsPage.categoryUntitled') }),
          value: category.id,
          disabled: true,
          types: [AutocompleteSelectItemType.noSelection],
        });
        items.push(...specifications.map((specification) => ({ value: specification.id, name: specification.name })));
      }
    }

    return items;
  };

  const handleChangeIsActive = (event: any) => {
    setChecked(event.target.checked);
    domain.ui.model.entity.isActive = event.target.checked;
  };

  const handleChangeTextIsRequired = (event: any) => {
    if (domain.ui.model.entity.conditionsTextMap) {
      domain.ui.model.entity.conditionsTextMap.isRequired = event.target.checked;
    }
  };

  useEffect(() => {
    domain.layoutDomain.setPageTitle(translate(`phrases.${domain.ui.model.entity.id ? `edit` : `addCategory`}`));
    setChecked(domain.ui.model.entity.isActive);
  }, [domain]);

  return (
    <FormModalComponent
      onCancel={() => { domain.onCancelUpdates() }}
      dataCy={`${domain.layoutDomain.ui.pageTitle.value}категорию`}
      domain={domain}
      modalTitle={modalTitle}
      onSave={() => { domain.onSaveUpdates() }}
      buttonText={buttonText}
    >
      <FormTextField
        errorMessage={domain.getValidationErrorFor('name')?.message}
        value={domain.ui.model.entity.name}
        onChange={(newValue: any) => {
          domain.ui.model.entity.name = newValue;
        }}
        label={translate('systemSpecificationsPage.name')}
        required={true}
        inputProps={{
          'data-cy': 'spec-title'
        }}
        size={'small'}
        paddingSizing={1}
      />

      <FormTextArea
        errorMessage={domain.getValidationErrorFor('description')?.message}
        value={domain.ui.model.entity.description}
        onChange={domain.getUpdateFieldHandler('description')}
        label={translate('systemSpecificationsPage.description')}
        required={true}
        dataCy='spec-description'
        paddingSizing={1}
      />

      <FormTextField
        type="text"
        errorMessage={domain.getValidationErrorFor('orderIndex')?.message}
        value={domain.ui.model.entity.orderIndex?.toString()}
        onChange={domain.getUpdateIntegerFieldHandler('orderIndex')}
        label={translate('systemSpecificationsPage.ordering')}
        dataCy='spec-order-number'
        inputProps={{ maxLength: 9 }}
        size={'small'}
        paddingSizing={1}
      />

      {!isEdit && !ConfigManager.getConfig().extensions.include.includes('asm') && <CategoryTypeRadioBlock
        onChangeType={(newValue) => {
          domain.ui.model.entity.categoryType = newValue
          domain.ui.model.entity.conditionsMap = {
            useIfSpecificationEntitiesIds: [],
            useIfNotSpecificationEntitiesIds: [],
          }
          domain.ui.model.entity.conditionsTextMap = {
            isRequired: false,
            defaultValue: '',
          }

        }}
        value={domain.ui.model.entity.categoryType}
      />}

      {domain.ui.model.entity.categoryType === SpecificationCategoryType.specificationCategoryValue ?
        <>
          <FormMultiAutocomplete
            errorMessage={domain.getValidationErrorFor('conditionMap')?.message}
            label={translate('systemSpecificationsPage.showIfHaveValues')}
            selected={domain.ui.model.entity.conditionsMap?.useIfSpecificationEntitiesIds || []}
            key={'useIfSpecificationEntitiesIds'}
            onChangeSelect={(newValue: any) => {
              if (!domain.ui.model.entity.conditionsMap) {
                domain.ui.model.entity.conditionsMap = {
                  useIfSpecificationEntitiesIds: [],
                  useIfNotSpecificationEntitiesIds: [],
                };
              }
              domain.ui.model.entity.conditionsMap.useIfSpecificationEntitiesIds = newValue.map((item) => item.value);
            }}
            values={getSpecificationsValues()}
            dataCy=''
          />
          <FormMultiAutocomplete
            errorMessage={domain.getValidationErrorFor('conditionMap')?.message}
            label={translate('systemSpecificationsPage.showIfHaveNotValues')}
            key={'useIfNotSpecificationEntitiesIds'}
            selected={domain.ui.model.entity.conditionsMap?.useIfNotSpecificationEntitiesIds || []}
            onChangeSelect={(newValue: any) => {
              if (!domain.ui.model.entity.conditionsMap) {
                domain.ui.model.entity.conditionsMap = {
                  useIfSpecificationEntitiesIds: [],
                  useIfNotSpecificationEntitiesIds: [],
                };
              }
              domain.ui.model.entity.conditionsMap.useIfNotSpecificationEntitiesIds = newValue.map((item) => item.value);
            }}
            values={getSpecificationsValues()}
            dataCy=''
          />
        </> :
        <>
          <FormTextArea
            value={domain.ui.model.entity?.conditionsTextMap?.defaultValue || ''}
            onChange={(newValue) => {
              domain.getUpdateDefaultText(newValue)
            }}
            
            label={translate("phrases.defaultValues")}
            dataCy='spec-description'
            paddingSizing={1}
            size={'small'}
            autoRows={true}
            errorMessage={domain.getValidationErrorFor('conditionsTextMap')?.message}
          />
          <Grid width={'100%'}>
            <FormControlLabel
              style={{ width: '100%', padding: '0px 5px', margin: 0 }}
              control={
                <Checkbox
                  sx={{
                    '&.Mui-checked': {
                      color: '#66BB6A',
                    },
                    '& .MuiSvgIcon-root': {
                      fontSize: 24,
                    },
                  }}
                  defaultChecked={true}
                  onChange={handleChangeTextIsRequired}
                  checked={domain.ui.model.entity?.conditionsTextMap?.isRequired || false}
                />
              }
              label={translate('phrases.requiredCategory')}
            />
          </Grid>
        </>
      }
      <Grid width={'100%'}>
        <FormControlLabel
          style={{ width: '100%', padding: '0px 5px', margin: 0 }}
          control={
            <Checkbox
              sx={{
                '&.Mui-checked': {
                  color: '#66BB6A',
                },
                '& .MuiSvgIcon-root': {
                  fontSize: 24,
                },
              }}
              defaultChecked={true}
              onChange={handleChangeIsActive}
              checked={isChecked}
            />
          }
          label={translate('phrases.activeCategory')}
        />
      </Grid>

    </FormModalComponent >

  );
});

const CategoryTypeRadioBlock = ({ onChangeType, value }) => {
const { translate } = useI18n();

  return (
    <FormControl sx={{
      padding: 1,
      width: '100%',
      paddingLeft: '17px'
    }}>
      <RadioGroup
        value={value}
        onChange={(event) => {
          onChangeType(event.target.value)
        }}
        defaultValue={SpecificationCategoryType.specificationCategoryValue}
      >
        <FormControlLabel
          value={SpecificationCategoryType.specificationCategoryValue}
          control={<Radio
            size="small"
            sx={{
              '&.Mui-checked': {
                color: '#66BB6A',
              },
              "& .MuiSvgIcon-root": {
                height: 22,
                width: 22,
              },
              '&:hover': {
                backgroundColor: 'transparent',
              },
              '& .MuiTouchRipple-root': {
                display: 'none',
              },
            }} />}
          label={translate('buttonsText.withValue')}
        />
        <FormControlLabel
          value={SpecificationCategoryType.specificationCategoryText}
          control={
            <Radio

              size="small"
              sx={{
                '&.Mui-checked': {
                  color: '#66BB6A',
                },
                "& .MuiSvgIcon-root": {
                  height: 22,
                  width: 22,
                },
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '& .MuiTouchRipple-root': {
                  display: 'none',
                },
              }}
            />
          }
          label={translate('buttonsText.withTextField')}
          />
      </RadioGroup>
    </FormControl>
  )
}
