import { IRestCTFStatisticService } from "../../../../../../service/statistc/IRestCTFStatisticService";
import { RestCTFStatisticService } from "../../../../../../service/statistc/RestCTFStatisticService";
import { ICommonStatisticDomain } from "./ICommonStatisticDomain";

export class CommonStatisticDomain implements  ICommonStatisticDomain {
  restCTFStatisticService: IRestCTFStatisticService;

  constructor() {
    this.restCTFStatisticService = new RestCTFStatisticService();
  }
  loadUserCount = () =>  this.restCTFStatisticService.getUserCount()
  loadMostAndLessPopularChallenges = () => this.restCTFStatisticService.getMostAndLessPopularChallenges()
}