import LinkIcon from '@mui/icons-material/Link';
import SyncIcon from '@mui/icons-material/Sync';
import { Grid, Pagination, Paper, Typography } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import { Theme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { ICommonProperties } from '../../../../../../../common/properties/ICommonProperties';
import { UserProjectRoleAction } from '../../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { UserSystemRoleModelPermissionMap } from '../../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { TaskManagerConnectionState } from '../../../../../../../service/taskManager/ITaskManager';
import { RequirementForm } from '../../../../../../admin/page/requirement/entity/form/RequirementForm';
import { SingleAutoCompleteViewForTable } from '../../../../../../design/table/edit/TableSingleAutocompleteView';
import { ApplicationDetailDomain } from '../../store/ApplicationDetailDomain';
import { RequirementViewModel, RequirementViewModelState } from '../../store/RequirementViewModel';
import { EditCustomRequirementIcon, HiddenInfoIcon, HiddenRequirementInfoBlock } from './ActiveRequirementsTableParts';
import { ApplicationDetailsMode } from '../../store/ApplicationDetailsMode';
import { RichTextArea } from '../../../../../../design/RichTextEditor/RichTextEditor';

export interface IActiveRequirementTableProperties extends ICommonProperties {
  domain: ApplicationDetailDomain;
}

export const ActiveRequirementsTable = observer(({ domain }: IActiveRequirementTableProperties) => {
  return domain.ui.isFirstBootLoaded.value === true ? <LoadedActiveRequirementsTable domain={domain} /> : <div />;
});

export interface ITableData {
  appReqId: string;
  requirement: RequirementViewModel;
}

export const LoadedActiveRequirementsTable = observer(({ domain }: IActiveRequirementTableProperties) => {
  const [tableData, setTableData] = useState<ITableData[]>([]);
  const setPermission = (domain: ApplicationDetailDomain) => {
    const allRequirements = domain.ui.renderRequirements.list;

    if (domain.ui.project.entity.id) {
      const isCanSeeAllRequirements = domain.layoutDomain.userHaveAnyAccess(
        [UserSystemRoleModelPermissionMap['project-not-in-team-permission-show-unverified-requirements-access']],
        {
          id: domain.ui.project.entity.id,
          permissions: [UserProjectRoleAction.showUnverifiedRequirements],
        },
      );

      if (!isCanSeeAllRequirements) {
        domain.ui.requirements.setList(
          allRequirements.filter((requirement) => requirement.state !== RequirementViewModelState.newest),
        );
        domain.ui.renderRequirements.setList(
          allRequirements.filter((requirement) => requirement.state !== RequirementViewModelState.newest),
        );
      }
    }
  };

  useEffect(() => {
    const tableDataArray: ITableData[] = [];
    if (domain.ui.application.entity.dataId) {
      domain.ui.renderRequirements.list.forEach((renderReq) => {
        const appReq = domain.ui.applicationReq.list.find((appReq) => renderReq.id === appReq.requirementId);

        if (appReq) {
          tableDataArray.push({ appReqId: appReq.id || '', requirement: renderReq });
        };
      });
    } else {
      domain.ui.renderRequirements.list.forEach((renderReq) => {

        tableDataArray.push({ appReqId: renderReq.id || '', requirement: renderReq });
      });
    }

    setTableData(tableDataArray);
  }, [domain.ui.renderRequirements.list])

  useEffect(() => {
    setPermission(domain);
  }, [domain]);

  useEffect(() => {
    if (domain.ui.mode.value === ApplicationDetailsMode.edit) {
      domain.loadApplicationDataPreview();
    }
  }, [
    domain.ui.editApplication.entity.specificationsIds,
    domain.ui.editApplication.entity.performerTypeId,
    domain.ui.editApplication.entity.templateId,
  ]);

  return (
    <RequirementTable
      isLoading={domain.ui.isLoading.value}
      statusesColumns={domain.ui.statusesColumns.value}
      applicationData={domain.ui.applicationData.entity}
      tableData={tableData}
      requirementCategoriesValues={domain.ui.requirementCategoriesValues.value}
      isHaveActionButtons={domain.ui.isHaveActionButtons.value}
      domain={domain}
    />
  );
});

interface TableProperties {
  isLoading: boolean;
  statusesColumns: any[];
  tableData: any[];
  requirementCategoriesValues: any[];
  applicationData: any;
  isHaveActionButtons: boolean;
  domain: ApplicationDetailDomain;
}

function stringDescendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  const aValue = `${a[orderBy]}`.toLocaleLowerCase();
  const bValue = `${b[orderBy]}`.toLocaleLowerCase();
  return bValue.localeCompare(aValue, undefined, { numeric: true, sensitivity: 'base' });
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof ITableData>(
  order: Order,
  orderBy: Key,
  comparator: any,
): (a: ITableData, b: ITableData) => number {
  const result =
    order === 'desc'
      ? (a: ITableData, b: ITableData) => comparator(a.requirement, b.requirement, orderBy, order)
      : (a: ITableData, b: ITableData) => -comparator(a.requirement, b.requirement, orderBy, order);
  return result;
}

function stableSort<T>(array: T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: any;
  label: string;
  numeric: boolean;
  padding?: number;
  descendingComparator: any;
}

interface EnhancedTableProps {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (event: React.MouseEvent<unknown>, property: any) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
  headCells: HeadCell[];
  domain: ApplicationDetailDomain;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { classes, order, orderBy, onRequestSort, headCells, numSelected, rowCount, domain } = props;
  const createSortHandler = (property: any) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow key={'head'}>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={domain.ui.toggleSelectAllRequirements}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>

        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

interface PaginationState {
  page: number;
  numberPerPage: number;
  pageCount: number;
  currentData: any[]; // Замените any на тип вашего currentData, если это возможно
}


const RequirementTable = observer(
  ({
    isLoading,
    statusesColumns,
    tableData,
    requirementCategoriesValues,
    applicationData,
    isHaveActionButtons,
    domain,
  }: TableProperties) => {
    const classes = useStyles();
    const [order, setOrder] = React.useState<Order>('asc');
    const [orderBy, setOrderBy] = React.useState<any>('');

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: any) => {
      const isAsc = orderBy === property && order === 'asc';
      setOrder(isAsc ? 'desc' : 'asc');
      setOrderBy(property);
    };

    const headCells: HeadCell[] = [
      {
        id: 'categoryId',
        numeric: false,
        disablePadding: true,
        label: 'Категория',
        descendingComparator: domain.ui.sortCategoryColumn,
      },
      {
        id: 'shortName',
        numeric: false,
        disablePadding: false,
        label: 'Короткое название',
        descendingComparator: stringDescendingComparator,
      },
      {
        id: 'description',
        numeric: false,
        disablePadding: false,
        label: 'Описание',
        descendingComparator: stringDescendingComparator,
      },
    ];

    statusesColumns.forEach((column) => {
      headCells.push({
        id: column.title,
        numeric: false,
        disablePadding: false,
        label: column.title,
        descendingComparator: column.customSort,
      });
    });

    if (isHaveActionButtons) {
      headCells.push({
        id: 'actions',
        numeric: false,
        disablePadding: false,
        label: 'Тип требования',
        descendingComparator: domain.ui.sortByCreateType,
      });
    }

    const [pagination, setPagination] = useState<PaginationState>({
      page: domain.paginationPage.value || 1,
      numberPerPage: 20,
      pageCount: 0,
      currentData: [],
    });

    const handlePageClick = (event, page) => {
      domain.paginationPage.setValue(page);
      setPagination({ ...pagination, page });
    };

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          <TableContainer >
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
              stickyHeader
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={domain.ui.selectedRequirementsIds.value.length}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={tableData.length}
                headCells={headCells}
                domain={domain}
              />
              <TableBody>
                <TableCells
                  domain={domain}
                  isHaveActionButtons={isHaveActionButtons}
                  rowsData={tableData}
                  requirementCategoriesValues={requirementCategoriesValues}
                  statusesColumns={statusesColumns}
                  order={order}
                  orderBy={orderBy}
                  headCells={headCells}
                  setPagination={setPagination}
                  pagination={pagination}
                />
              </TableBody>
            </Table>
          </TableContainer>
          <Paper style={{ paddingTop: '15px', paddingBottom: '15px', display: 'flex', justifyContent: 'center' }}>
            <Pagination
              count={pagination.pageCount}
              size="large"
              page={pagination.page}
              defaultPage={1}
              variant="outlined"
              shape="rounded"
              onChange={handlePageClick}
            />
          </Paper>
        </Paper>
      </div>
    );
  },
);

interface TableCellsProperties {
  domain: ApplicationDetailDomain;
  rowsData: ITableData[];
  isHaveActionButtons: boolean;
  requirementCategoriesValues: any[];
  statusesColumns: any[];
  order: Order;
  orderBy: any;
  headCells: HeadCell[];
  pagination: PaginationState;
  setPagination: React.Dispatch<React.SetStateAction<PaginationState>>;
}

const TableCells = observer(
  ({
    domain,
    isHaveActionButtons,
    headCells,
    requirementCategoriesValues,
    rowsData,
    statusesColumns,
    order,
    orderBy,
    pagination,
    setPagination,
  }: TableCellsProperties) => {
    const headOrder = headCells.find((cell) => cell.id === orderBy);
    const comparator = headOrder?.descendingComparator || stringDescendingComparator;

    useEffect(() => {
      let activeData = rowsData;
      if (domain.ui.requirementsIdsToRender.value) {
        activeData = rowsData.filter((rowData) => {
          return domain.ui.requirementsIdsToRender.value?.includes(rowData.appReqId || '');
        });
      }

      const sortData = stableSort<ITableData>(activeData, getComparator(order, orderBy, comparator))

      const pageCount = (sortData.length % pagination.numberPerPage) ?
        (Math.trunc(sortData.length / pagination.numberPerPage) + 1) :
        Math.trunc(sortData.length / pagination.numberPerPage);
      setPagination((prevState: any) => ({
        ...prevState,
        pageCount: pageCount,
        currentData: sortData.slice(
          (pagination.page - 1) * pagination.numberPerPage,
          (pagination.page - 1) * pagination.numberPerPage + pagination.numberPerPage,
        ),
      }));
    }, [rowsData, order, orderBy, pagination.numberPerPage, pagination.page, domain.ui.requirementsIdsToRender.value]);

    useEffect(() => {
      let activeData = rowsData;
      if (domain.ui.requirementsIdsToRender.value) {
        activeData = rowsData.filter((rowData) => {
          return domain.ui.requirementsIdsToRender.value?.includes(rowData.appReqId || '');
        });
      }

      const sortData = stableSort<ITableData>(activeData, getComparator(order, orderBy, comparator))
      setPagination((prevState: any) => ({
        ...prevState,
        page: domain.paginationPage.value || 1,
        currentData: sortData.slice(0, pagination.numberPerPage),
      }));
    }, [domain.ui.requirementsIdsToRender.value])
    return (
      <React.Fragment>
        {pagination.currentData.map(
          (rowData: ITableData, index: number) => {
            return (
              <TableCellItem
                domain={domain}
                isHaveActionButtons={isHaveActionButtons}
                requirementCategoriesValues={requirementCategoriesValues}
                rowData={rowData}
                statusesColumns={statusesColumns}
                key={`row-item-${rowData.appReqId}`}
                isShow={
                  Array.isArray(domain.ui.requirementsIdsToRender.value)
                    ? domain.ui.requirementsIdsToRender.value.includes(rowData.appReqId || '')
                    : true
                }
                isSelected={domain.ui.selectedRequirementsIds.value.includes(rowData.appReqId || '')}
              />
            );
          },
        )}
      </React.Fragment>
    );
  },
);

interface TableCellItemProperties {
  domain: ApplicationDetailDomain;
  isHaveActionButtons: boolean;
  requirementCategoriesValues: any[];
  statusesColumns: any[];
  rowData: ITableData;
  isShow: boolean;
  isSelected: boolean;
}

const TableCellItem = observer(
  ({
    domain,
    rowData,
    isShow,
    requirementCategoriesValues,
    statusesColumns,
    isHaveActionButtons,
    isSelected,
  }: TableCellItemProperties) => {
    const labelId = `enhanced-table-checkbox-${rowData.appReqId}`;
    const requirementNewMessagesCounter =
      domain.ui.applicationNewMessagesState.value?.requirementNewMessages?.find(
        (data) => data.requirementId === rowData.appReqId,
      )?.newMessages || 0;
    const requirementExternalLink =
      domain.ui.applicationJiraLinks.value.find((link) => link.requirementId === rowData.appReqId)?.link || null;

    const requirementCanBeResync =
      domain.ui.editJiraTaskManager?.entity?.connectionState === TaskManagerConnectionState.connected &&
      domain.ui?.applicationData?.entity?.savedRequirementsIds?.includes(rowData?.appReqId) &&
      (domain.ui.applicationJiraLinks.value.find((link) => link.requirementId === rowData.appReqId)?.isNotExists ?? true);

    const isHaveAccessToResyncIssue = domain.ui.isCanEditJira.value;

    // const isHaveAccessToResyncIssue =
    // domain.ui.userProjectRoleActions.value.includes(UserProjectRoleAction.editApplication) ||
    // domain.layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);

    const isOutOfResyncDelay =
      (new Date(domain.ui.editJiraTaskManager.entity.createDate as any).getTime() || 0) + 10 * 60 * 1000 <
      new Date().getTime();
    const isCanResyncIssues = isHaveAccessToResyncIssue && isOutOfResyncDelay;
    // const [syncButtonBlocked, setSyncButtonSynced] = useState(domain.isSyncButtonBlocked.value);
    const [syncButtonBlocked, setSyncButtonSynced] = useState(false);

    // useEffect(() => {
    //   setSyncButtonSynced(domain.isSyncButtonBlocked.value);
    // }, [domain.isSyncButtonBlocked.value])
    return (
      <React.Fragment>
        <TableRow
          hover
          role="checkbox"
          data-cy={isShow ? `${rowData.requirement.shortName}-table-row` : `${rowData.requirement.shortName}-table-row-display-none`}
          key={rowData.appReqId}
          selected={isSelected}
          style={{
            ...(rowData.requirement.backgroundColor && { backgroundColor: rowData.requirement.backgroundColor }),
            ...(!isShow && { display: 'none' }),
          }}
        >
          <TableCell style={{ width: '60px' }} padding="checkbox">
            <Checkbox
              sx={{
                '&.Mui-checked': {
                  color: 'white',
                },
              }}
              checked={isSelected}
              inputProps={{ 'aria-labelledby': labelId }}
              onClick={() => {
                domain.ui.toggleSelectRequirement(rowData.appReqId);
              }}
            />
          </TableCell>
          <TableCell
            style={{ width: '200px', paddingRight: '20px' }}
            component="th"
            id={labelId}
            scope="row"
            padding="none"
          >
            <SingleAutoCompleteViewForTable
              values={requirementCategoriesValues}
              selectData={(item: any) => item.categoryId}
              rowData={rowData.requirement}
            />
          </TableCell>
          <TableCell style={{ width: '50px' }} align="left">
            <Grid container spacing={1}>
              {requirementExternalLink && !requirementCanBeResync && (
                <Grid item>
                  <LinkIcon
                    onClick={() => {
                      window.open(requirementExternalLink, '_blank');
                    }}
                    style={{ cursor: 'pointer', fontSize: 20 }}
                  />
                </Grid>
              )}
              {isCanResyncIssues && !syncButtonBlocked && requirementCanBeResync && (
                <Grid item>
                  <SyncIcon
                    onClick={async () => {
                      setSyncButtonSynced(true);
                      await domain.resyncIssue(rowData.appReqId);
                      setSyncButtonSynced(false);
                    }}
                    style={{ cursor: 'pointer', fontSize: 20 }}
                  />
                </Grid>
              )}
              <Grid item>
                <Typography> {rowData.requirement.shortName} </Typography>
              </Grid>
              {requirementNewMessagesCounter > 0 && (
                <Grid item style={{ paddingLeft: 5 }}>
                  <Typography variant="caption"> ({requirementNewMessagesCounter})</Typography>
                </Grid>
              )}
            </Grid>
          </TableCell>
          <TableCell style={{ width: '500px' }} align="left">
            <RichTextArea data={rowData.requirement.description || ''} dataWithStyles={rowData.requirement.descriptionWithStyles || ''} />
          </TableCell>
          {statusesColumns.map((statusColumn: any) => {
            return <TableCell align="left">{statusColumn.render(rowData)}</TableCell>
          })}
          {isHaveActionButtons && (
            <TableCell style={{ width: '20px' }} align="right">
              <Grid container justifyContent="flex-end">
                {rowData.requirement.isHaveRequirementFormDetailPanel && (
                  <Grid item>
                    <EditCustomRequirementIcon
                      isOpen={rowData.requirement.isOpenRequirementFormDetailPanel}
                      onClick={() => domain.ui.toggleRequirementForm(rowData.requirement.id || '')}
                    />
                  </Grid>
                )}
                {rowData.requirement.isHaveInfoDetailPanel && (
                  <Grid item>
                    <HiddenInfoIcon
                      isOpen={rowData.requirement.isOpenInfoDetailPanel}
                      onClick={() => domain.ui.toggleDetailPanel(rowData.requirement.id || '')}
                    />
                  </Grid>
                )}
              </Grid>
            </TableCell>
          )}
        </TableRow>

        {rowData.requirement.isHaveInfoDetailPanel && rowData.requirement.isOpenInfoDetailPanel && (
          <TableRow
            data-cy={`hidden-info-block-${rowData.requirement.shortName}`}
          >
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <HiddenRequirementInfoBlock rowData={rowData} domain={domain} requirementId={rowData.requirement.id || ''} />
            </TableCell>
          </TableRow>
        )}
        {rowData.requirement.isHaveRequirementFormDetailPanel && rowData.requirement.isOpenRequirementFormDetailPanel && (
          <TableRow>
            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
              <div style={{ padding: '22px' }}>
                <RequirementForm
                  onSave={() => {
                    window.location.reload();
                  }}
                  onCancel={() => {
                    window.location.reload();
                  }}
                  onDelete={() => {
                    window.location.reload();
                  }}
                  isDelete={true}
                  layoutDomain={domain.layoutDomain}
                  requirementId={rowData.requirement.id}
                  requirementApplicationId={rowData.requirement.applicationId}
                />
              </div>
            </TableCell>
          </TableRow>
        )}
      </React.Fragment>
    );
  },
);
