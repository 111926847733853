import React, { Fragment, useEffect, useState } from 'react';
import { ConfigManager } from './ConfigManager';
import { ConfigFetchFailedError } from './errors/ConfigFetchFailedError';
import { ConfigNotLoadedError } from './errors/ConfigNotLoadedError';
export const ConfigLoader = ({ children }) => {
  const [error, setError] = useState(null);
  const [isLoadedConfig, setIsLoadedConfig] = useState(false);
  const baseUrl = window.location.protocol + '//' + window.location.host;
  useEffect(() => {
    const loadConfig = async () => {
      try {
        const response = await fetch(baseUrl + '/v2/api/config/public/frontend');
        if (!response.ok) {
          throw new ConfigFetchFailedError();
        }
        const config = await response.json();
        ConfigManager.setConfigFromServer(config);
      } catch (error) {
        setError(error)
        throw error;
      }
      setIsLoadedConfig(true);
    };
    if (!isLoadedConfig) {
      loadConfig().catch(error => {
        throw new ConfigNotLoadedError()
      });
    }
  });

  if (error) {
    throw error;
  }

  return isLoadedConfig ? children : <Fragment />;
};
