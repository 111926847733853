
import { CTFConfigModule } from "../../../../../../service/config/CTFConfigModule";
import { ICTFVideoBlockUI } from "./ICTFVideoBlockUI";

export class CTFVideoBlockUI implements ICTFVideoBlockUI {
  videoLink?: string;

  constructor() {
    this.createLinks();
  }

  private createLinks() {
    this.videoLink = CTFConfigModule.serverConfig.ctfVideoLink;
  }
}