import { IContainer } from '../../../../../../common/container/IContainer';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { saveFileInBrowser } from '../../../../../../common/utils/saveFileInBrowser';
import { IRequirementModel } from '../../../../../../service/requirement/entity/IRequirementModel';
import { IRequirementSearch } from '../../../../../../service/requirement/entity/IRequirementSearch';
import { RequirementType } from '../../../../../../service/requirement/entity/RequirementType';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { DataTableDomain } from '../../../../../design/dataTable/store/DataTableDomain';
import { DefaultTableSlots } from '../../../../../design/dataTable/store/DataTableUI';
import { IDataTableDomain } from '../../../../../design/dataTable/store/IDataTableDomain';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { AppBar } from './appBar/AppBar';
import { RequirementEntityContextMenuDomain } from './context/RequirementEntityContextMenuDomain';
import { RequirementEntityDetailDomain } from './detail/RequirementEntityDetailDomain';
import { RequirementEntityDrawerDomain } from './drawer/RequirementEntityDrawerDomain';
import { RequirementEntityAdminFilter } from './filter/RequirementEntityAdminFilter';
import { RequirementEntityModalDomain } from './modal/RequirementEntityModalDomain';
import { RequirementEntityAdminUI } from './RequirementEntityAdminUI';

export class CustomAppBar {
  Toolbar = AppBar;
}

export class RequirementEntityAdminDomain
  extends DataTableDomain<IRequirementModel, IRequirementSearch>
  implements IDataTableDomain<IRequirementModel, IRequirementSearch> {
  public ui: RequirementEntityAdminUI;
  public filterDomain: RequirementEntityAdminFilter;
  public detailDomain: RequirementEntityDetailDomain;
  public isCanResyncRequirements = injectPrimitive<boolean>(false);
  constructor(
    layoutDomain: IMainLayoutDomainStore,
    container: IContainer,
    filterDomain: RequirementEntityAdminFilter | null = null,
    public rootPrivateServices = injectRootService(layoutDomain.serviceType.value),
  ) {
    super(layoutDomain, rootPrivateServices.requirement.entity);
    const ModalDomainConstructor = container.get(RequirementEntityModalDomain);
    const DrawerDomainConstructor = container.get(RequirementEntityDrawerDomain);
    this.modalDomain = new ModalDomainConstructor(rootPrivateServices.requirement.entity, layoutDomain, this);
    this.drawerDomain = new DrawerDomainConstructor(rootPrivateServices.requirement.entity, layoutDomain, this);
    this.contextMenuDomain = new RequirementEntityContextMenuDomain({
      entityService: rootPrivateServices.requirement.entity,
      dataTableDomain: this,
      layoutDomain,
    });
    this.detailDomain = new RequirementEntityDetailDomain();
    this.ui = new RequirementEntityAdminUI(this, null);
    this.ui.tableSlots.setEntity(new CustomAppBar() as DefaultTableSlots);
    this.filterDomain = filterDomain || new RequirementEntityAdminFilter(this.rootPrivateServices, this);
    this.ui.rows.searchRequestForAnyLoadData.setValue({ filter: { type: { equal: RequirementType.system } } });
    this.ui.features.setValue({
      isCanBulkActivate: true,
      isCanBulkDeactivate: true,
      isCanCreate: true,
      isCanDelete: true,
      isCanEdit: true,
      isCanDownloadReport: true,
      isCanRedirectDetail: false,
    });
    this.ui.filterType.setValue('drawerFilter')
    this.ui.tableTitle.setValue('Шаблоны требований')
    this.ui.tableSubtitle.setValue('Требования')
    this.ui.tableOptions.checkboxSelection.setValue(true);
  }

  async setPermissions() {
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirements-create'],
    ]);
    const isCanDelete = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirements-delete'],
    ]);
    const isCanUpdate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirements-update'],
    ]);
    const isResyncReq = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['settings-requirements-accept'],
    ]);
    this.ui.isCanEdit.setValue(isCanUpdate);
    this.ui.isCanCreate.setValue(isCanCreate);
    this.ui.isCanDelete.setValue(isCanDelete);
    this.isCanResyncRequirements.setValue(isResyncReq);
  }

  async loadData() {
    await this.loadDependencies();
    await super.loadData();
    await this.filterDomain.start();
    await this.filterDomain.triggerSearch()
  }

  async loadDetail() {
    this.detailDomain.ui.isDetail.setValue(false);
    const entitiesVendorDetails = await this.rootPrivateServices.requirement.vendor.search({ limit: 100000 });
    const additionalColumnDetails = await this.rootPrivateServices.additionalColumn.content.search({ limit: 100000 });
    const additionalColumnEntity = await this.rootPrivateServices.additionalColumn.entity.search({ limit: 100000 });
    this.detailDomain.ui.detailEntities.setList(entitiesVendorDetails.data);
    this.detailDomain.ui.secondDetailEntities.setList(additionalColumnDetails.data);
    this.detailDomain.ui.detailKey.setValue('requirementsIds');
    this.detailDomain.ui.isArray.setValue(true);
    this.detailDomain.ui.secondDetailKey.setValue('requirementsIds');
    this.detailDomain.ui.isSecondArray.setValue(true);
    this.detailDomain.ui.isMultipleDetail.setValue(true);
    this.detailDomain.ui.additionalColumnEntity.setList(additionalColumnEntity.data);
    this.filterDomain.ui.isShowTextSearch.setValue(false);
  }

  async loadDependencies() {
    const [
      categoriesSearchResult,
      specificationsSearchResult,
      entitiesSearchResult,
      performersSearchResult,
      usersSearchResult,
    ] = await Promise.all([
      this.rootPrivateServices.requirement.category.search({ limit: 100000 }),
      this.rootPrivateServices.specification.entity.search({ limit: 100000 }),
      this.rootPrivateServices.tag.entity.search({ limit: 1000000 }),
      this.rootPrivateServices.performer.type.search({ limit: 1000000 }),
      this.rootPrivateServices.user.entity.search({ limit: 1000000 }),
    ]);
    this.ui.categories.setList(categoriesSearchResult.data);
    this.ui.specifications.setList(specificationsSearchResult.data);
    this.ui.tags.setList(entitiesSearchResult.data);
    this.ui.performers.setList(performersSearchResult.data);
    this.ui.users.setList(usersSearchResult.data);
  }

  saveFile(filename: string, blob: any) {
    saveFileInBrowser(filename, blob);
  }

  padTo2Digits(num) {
    return num.toString().padStart(2, '0');
  }

  async onHandleDownload(format = 'docx', name = 'Шаблоны требований'): Promise<any> {
    switch (format) {
      case 'docx':
      default:
        const blobDocx = await this.rootPrivateServices.requirement.entity.getReport(
          format,
        );
        const date = new Date();
        const dateForName = [
          this.padTo2Digits(date.getDate()),
          this.padTo2Digits(date.getMonth() + 1),
          date.getFullYear(),
        ].join('.');

        this.saveFile(`${name}_${dateForName}.docx`, blobDocx);
        break;
    }
  }

  async acceptRequirements(notSavedReqs: IRequirementModel[]) {
    try {
      if (notSavedReqs.length) {
        await this.rootPrivateServices.requirement.entity.acceptRequirements(notSavedReqs);
      }
      this.ui.rows.selectionEntities.setList([]);
    } catch (error) {

    }
  }

  searchRequest(searchTerm) {
    this.ui.isLoading.setValue(true);
    this.ui.rows.searchTerm.setValue(searchTerm);
    const searchRegex = new RegExp(this.ui.rows.escapeRegExp(searchTerm), 'i');

    const filteredRows = [...this.ui.rows.entities.list].filter((row) => {
      const fieldsToCheck = ['shortName', 'description']; // todo - разобраться с поиском

      return fieldsToCheck.some((field) => {
        const value = this.ui.rows.getFieldValueAsString(field, row[field]);
        return searchRegex.test(value);
      });
    });

    let finalyFilteredRows = filteredRows

    const rowsIds = finalyFilteredRows.map((item: any) => item?.id);

    finalyFilteredRows = [
      ...finalyFilteredRows,
      ...this.ui.rows.selectionEntities.list.filter((selectionEntity: any) => !rowsIds.includes(selectionEntity?.id)),
    ];
    this.ui.isLoading.setValue(false);
    return finalyFilteredRows
  }

  getRequirementsWord(count) {
    if (count % 100 >= 11 && count % 100 <= 14) {
      return count + " требований";
    }

    let lastDigit = count % 10;
    if (lastDigit === 1) {
      return count + " требование";
    } else if (lastDigit >= 2 && lastDigit <= 4) {
      return count + " требования";
    } else {
      return count + " требований";
    }
  }
}
