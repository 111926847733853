import { UserSystemRoleModelPermissionMap } from '../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';

export type IApplicationRouterExtension = {
  path: string;
  pageTitle: string;
  component: any;
  allowedPermissions: UserSystemRoleModelPermissionMap[];
  showNavBar?: boolean;
  showPaddings?: boolean;
  fullHeight?: boolean;
  overflowY?: boolean;
}

export const ApplicationRouterExtensions: IApplicationRouterExtension[] = [];
