import { IConfig } from './IConfig';
import { IENVList } from './IENVList';
import { IPublicFrontendConfigFromServer } from './IPublicFrontendConfigFromServer';
import { readFrontEnv } from './readFrontEnv';

let configCache: IConfig | null = null;

const DEFAULT_PREFIX = 'REACT_APP_';

export class ConfigManager {
  static serverConfig: IPublicFrontendConfigFromServer;

  static getConfig(): IConfig {
    configCache = configCache || {
      extensions: {
        include: readFrontEnv<string, IENVList >({
          name: 'EXTENSIONS',
          prefix: DEFAULT_PREFIX,
        }),
        configs: ConfigManager.serverConfig?.extensionsConfigs || [],
        getExtensionsConfigByName: (name: string, defaultValue: any) =>
          ConfigManager.getConfig().extensions.configs.find((config) => config.name === name)?.value ?? defaultValue,
      },
      localization: readFrontEnv<string, IENVList >({
          name: 'LOCALIZATION',
          prefix: DEFAULT_PREFIX,
          defaultValue: ['ru']
        })[0],
      url: {
        api: {
          admin: ConfigManager.getSiteDomain('/v2/admin'),
          public: ConfigManager.getSiteDomain('/v2/api'),
          file: ConfigManager.getSiteDomain('/file'),
        },
      },
      defaultValues: {
        jiraIssuePrefix: ConfigManager.serverConfig?.jiraPrefix || '',
      },
      license: {
        isSupport: ConfigManager.serverConfig?.isShowLicense || false,
        licenseString: ConfigManager.serverConfig?.licenseDataAsString || '',
      },
      text: {
        projectTitle: ConfigManager.serverConfig?.title || 'START X',
      },
      ui: {
        project: {
          form: {
            settings: ConfigManager.serverConfig?.projectSettings || [],
            isUnitedWithApplicationByDefault: false,
          },
        },
        application: {
          acceptWithVersion: false,
        },
      },
    };

    return configCache;
  }

  static setConfigFromServer(externalConfig: IPublicFrontendConfigFromServer): void {
    ConfigManager.serverConfig = externalConfig;

    if (configCache) {
      configCache.extensions.configs = ConfigManager.serverConfig?.extensionsConfigs || [];
      configCache.defaultValues.jiraIssuePrefix = ConfigManager.serverConfig?.jiraPrefix || '';
      configCache.license.isSupport = ConfigManager.serverConfig?.isShowLicense || false;
      configCache.license.licenseString = ConfigManager.serverConfig?.licenseDataAsString || '';
      configCache.text.projectTitle = ConfigManager.serverConfig?.title || 'START X';
      configCache.ui.project.form.settings = ConfigManager.serverConfig?.projectSettings || [];
    }
  }

  static getSiteDomain(uri: string): string {
    return window.location.protocol + '//' + window.location.host + uri;
  }
}
