import { Container, CssBaseline, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';

import { useI18n } from '../../application/language/useI18n';
import { MainLayoutBottomLogoMenu } from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';
import {
  ErrorPageOrganization,
  isShowOnlyEmail,
} from '../../view/layout/main/parts/LeftNavigation/parts/MainLayoutLeftNavigationTopLogo';
import { ContainerContext } from '../container/ContainerContext';

type IProps = {
  error: Error;
};

const ErrorPage: React.FC<IProps> = () => {
  const context = useContext(ContainerContext);
  const HeaderLogoComponent = context.get(MainLayoutBottomLogoMenu);
  const ErrorPageOrganizationContent = context.get(ErrorPageOrganization);
  const isShowOnlyEmailValue = context.get(isShowOnlyEmail)();
  const classes = useStyles();
  const { translate, getCurrentLanguage } = useI18n();

  return (
    <>
      <CssBaseline />
      <Container disableGutters maxWidth={false}>
        <Typography className={classes.background}>
          <Typography className={classes.topIcons} style={{ width: getCurrentLanguage() === 'ru' ? '220px' : '370px' }}>
            <HeaderLogoComponent />
          </Typography>

          <Typography>
            <img src="/pictures/renderError.svg" />
          </Typography>

          <Typography variant="h3" className={classes.headlineText}>
            {translate('featureUnavailablePage.title')}
          </Typography>

          <Typography className={classes.mainText}>
            {translate('featureUnavailablePage.message')}
          </Typography>

          {!isShowOnlyEmailValue && (
            <Typography className={classes.bottomContainer}>
              <Typography className={classes.supportText}>
                <Typography className={classes.supportRegular}>{translate('errorPage.writeUs')}</Typography>

                <div className={classes.supportBold}>
                  {getCurrentLanguage() === 'ru' ? 'support@startx.team' : 'support@humanfactor.ai'}
                </div>
              </Typography>

              {getCurrentLanguage() === 'ru' && <Typography className={classes.supportText}>
                <Typography className={classes.supportRegular}>{translate('errorPage.orCall')}</Typography>

                <Typography variant="h6" className={classes.supportBold}>
                  +7 499 677 19 07
                </Typography>
              </Typography>}
            </Typography>
          )}
          {isShowOnlyEmailValue && (
            <div className={classes.onlyEmailContainer}>
              <Typography className={classes.supportText}>
                <Typography className={classes.supportRegular}>{translate('errorPage.writeUs')}</Typography>

                <Typography variant="h6" className={classes.supportBold}>
                  ask@humanfactor.ai
                </Typography>
              </Typography>
            </div>
          )}
          <Typography variant="body2" className={classes.footerText}>
            {ErrorPageOrganizationContent(getCurrentLanguage())} {new Date().getFullYear()}
          </Typography>
        </Typography>
      </Container>
    </>
  );
};

export default ErrorPage;

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    margin: '0px',
    padding: '0px',
    background: '#344046 !important',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topIcons: {
    paddingBottom: '20px',
  },
  mainText: {
    width: '400px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingBottom: '50px',
  },
  headlineText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '48',
    paddingTop: '60px',
    paddingBottom: '30px',
  },
  bottomContainer: {
    display: 'flex',
    maxWidth: '900px',
    width: '100%',
    justifyContent: 'center',
    gap: 16
  },
  onlyEmailContainer: { display: 'flex', maxWidth: '900px', width: '100%', justifyContent: 'center' },
  supportText: {
    padding: 10,
    border: '1px solid #47575F',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '438px',
    height: '108px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32.02px',
  },
  supportBold: {
    color: '#FFF',
  },
  supportRegular: {
    color: '#9C9C9C',
  },
  footerText: {
    color: '#9C9C9C',
    fontSize: '12px',
    fontWeight: '200',
    position: 'absolute',
    bottom: '30px',
  },
}));
