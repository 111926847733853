import React from 'react';

import { BottomLogoMenu } from '../BottomLogoMenu';
import { useI18n } from '../../../../../../application/language/useI18n';

export const MainLayoutBottomLogoMenu = () => {
    const { getCurrentLanguage } = useI18n();
  
    return (
      <BottomLogoMenu
        containerStyle={
          getCurrentLanguage() === 'en' ? { display: 'flex', flexDirection: 'column', alignItems: 'center' } : undefined
        }
        iconStyle={getCurrentLanguage() === 'en' ? { width: '100px' } : undefined}
      />
    );
  };
