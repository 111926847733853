import React, { Component, ErrorInfo } from 'react';

import ErrorPage from '../ErrorPage';
import { IErrorBoundaryProps, IErrorBoundaryState } from './IErrorBoundary';

class ErrorBoundaryApp extends Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = { error: null };
  }

  static getDerivedStateFromError(error): IErrorBoundaryState {
    return { error: error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void { }

  render() {
    if (this.state.error) {
      return <ErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundaryApp;
