import { injectEntityList } from "../../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../../common/store/base/injectPrimitive";
import { IEntityListStore } from "../../../../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../../../../common/store/interface/IPrimitiveStore";
import { ICoreSearchRequestData } from "../../../../../../../../service/coreCommon/common/search/ICoreSearchRequestData";
import { ICTFUserResult } from "../../../../../../service/rating/interface/ICTFUserResult";
import { IRestRatingService } from "../../../../../../service/rating/IRestRatingService";
import { RestRatingService } from "../../../../../../service/rating/RestRatingService";
import { IRatingDomain } from "./IRatingDomain";

export class RaitingBaseDomain implements IRatingDomain {
  ratingRESTService: IRestRatingService;
  userNameSearchText: IPrimitiveStore<string>;
  ratingList: IEntityListStore<ICTFUserResult>;
  currentPage: IPrimitiveStore<number>;
  totalPage: IPrimitiveStore<number>;
  isFetching: IPrimitiveStore<boolean>;

  constructor() {
    this.ratingRESTService = new RestRatingService();
    this.userNameSearchText = injectPrimitive('');
    this.ratingList = injectEntityList<ICTFUserResult>([]);
    this.currentPage = injectPrimitive(1);
    this.totalPage = injectPrimitive(0);
    this.isFetching = injectPrimitive(false);
  }

  protected PER_PAGE = 10;

  protected createPageArg = () => {
    return {
      limit: this.PER_PAGE,
      offset: (this.currentPage.value - 1) * this.PER_PAGE
    }
  }

  protected addSearchFilter = (requstData:ICoreSearchRequestData<ICTFUserResult>) => {
    if (this.userNameSearchText.value.length > 0) {
      if (!requstData?.filter) {
        requstData.filter = []
      }
      requstData.filter?.push({ fieldName: 'userName', type: 'fullText', value: this.userNameSearchText.value })
    }
  };

  protected createArgsForSearch(): ICoreSearchRequestData<ICTFUserResult> {
    const data = {
      ...this.createPageArg()
    }
    this.addSearchFilter(data);

    return data;
  }

  protected loadingSearch = async () => this.ratingRESTService.search(this.createArgsForSearch());

  loadData = async () => {
    this.isFetching.setValue(true);
    try {
      const { totalCount, data } = await this.loadingSearch()
      this.totalPage.setValue(Math.ceil(totalCount / this.PER_PAGE));
      this.ratingList.setList(data);
    } finally {
      this.isFetching.setValue(false);
    }
  }


  hanldeChangePage = async  (newPage: number) => {
    if ((newPage <= 0 && this.currentPage.value === 1) || newPage >= this.totalPage.value && this.currentPage.value === this.totalPage.value  ) return;

    if (newPage <= 0) {
      this.currentPage.setValue(1)
    } else if (newPage >= this.totalPage.value) {
      this.currentPage.setValue(this.totalPage.value)
    } else {
      this.currentPage.setValue(newPage)
    }
    await this.loadData();
  }
  async hanldeChangeUserNameSearchText(newSearchValue: string) {
    this.currentPage.setValue(1);
    this.totalPage.setValue(0);
    this.userNameSearchText.setValue(newSearchValue);
    await this.loadData();
  }
}