import { IServiceModel } from '../../common/model/IServiceModel';
import { IStatusEntityModel } from '../../status/entity/IStatusEntityModel';
import { IStatusValueModel } from '../../status/value/IStatusValueModel';
import { IApplicationModelCustomInformationData } from './IApplicationCustomInformation';
import { IApplicationState } from './IApplicationState';

export interface IApplicationModel extends IServiceModel {
  id?: string;
  name?: string;
  projectId?: string;
  dataId?: string;
  specificationsIds?: string[];
  performerTypeId?: string;
  // requirementCount?: number
  statusValueData?: {
    statusEntity: IStatusEntityModel;
    statusValues: { statusValueEntity: IStatusValueModel; count: number }[];
  }[];
  state?: IApplicationState;
  customInformation?: IApplicationModelCustomInformationData;
  isDefault?: boolean;
  templateId?: string;
  isUnitedWithProject?: boolean;
  unitedProjectId?: string | null;
  acceptStatus?: ApplicationAcceptStatus;
  versionNumber?: string;
}

export interface IApplicationAuditListModel extends IApplicationModel {
  auditUser?: IAuditUser;
}

export interface IAuditUser {
  email: string;
  login: string;
  displayName: string;
}

export enum ApplicationAcceptStatus{
  draft = 'draft',
  review = 'review',
  accepted = 'accepted'
}
export interface IApplicationStatusCountModel {
  new?: number;
  inProgress?: number;
  suspend?: number;
  complete?: number;
}
