import { injectPrimitive } from "../../../../../../../../common/store/base/injectPrimitive";
import { IPrimitiveStore } from "../../../../../../../../common/store/interface/IPrimitiveStore";
import { ICTFUserResult } from "../../../../../../service/rating/interface/ICTFUserResult";
import { ICTFRatingForUI } from "./ICTFRatingForUI";
import { IRatingDomain } from "./IRatingDomain";
import { IRatingUI } from "./IRatingUI";

export class RatingUI implements IRatingUI {
  private alreadyBooted: IPrimitiveStore<boolean>;
  constructor(
    private ratingDomain: IRatingDomain,
  ) {
    this.alreadyBooted = injectPrimitive(false);

  }

  private ratingForUIDTO = (rating: ICTFUserResult): ICTFRatingForUI => ({
    ...rating,
    id: `${rating.userName}+${rating.score.number}+${rating.position}`,
  })

  getRatingData = (): ICTFRatingForUI[] => this.ratingDomain.ratingList.list.map(this.ratingForUIDTO);

  getCurrentPage = (): number => this.ratingDomain.currentPage.value;

  getTotalPage = (): number => this.ratingDomain.totalPage.value;

  boot = async (): Promise<void> => {
    if (this.alreadyBooted.value) return;

    this.ratingDomain.loadData();

    this.alreadyBooted.setValue(true)
  }

  handleChangePage = (newPage: number) => {
    this.ratingDomain.hanldeChangePage(newPage);
  }

  onChangeSearch = (event) => {
    this.ratingDomain.hanldeChangeUserNameSearchText(String(event.target.value))
  }
}