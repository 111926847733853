export const saveFileInBrowser = (filename: string, blob:  Blob | MediaSource) => {
  const blobUrl = URL.createObjectURL(blob);
  const link: HTMLAnchorElement = document.createElement('a');
  link.download = filename;
  link.href = blobUrl;

  document.body.appendChild(link);
  link.click();

  setTimeout(() => {
    link.remove();
    window.URL.revokeObjectURL(blobUrl);
    link.remove();
  }, 0);
}