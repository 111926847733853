import { Card, CardActions, CardContent, Grid, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext, useEffect, useState } from 'react';

import { ConfigManager } from '../../../../application/config/ConfigManager';
import { useI18n } from '../../../../application/language/useI18n';
import { ContainerContext } from '../../../../common/container/ContainerContext';
import { ListItemLink, ListItemLinkColor } from '../../../design/list/ListItemLink';
import { IMainLayoutDomainStore } from '../../../layout/main/store/domain/IMainLayoutDomainStore';
import { IPageWithLayoutProperties } from '../../../layout/page/IPageWithLayoutProperties';
import { ISettingsButton, ISettingsItem } from './ISettingsItem';
import { SettingsPageExtension } from './SettingsPageExtension';
import { IntegrationSettingsExtension } from './SettingsPageExtension';
import { SettingsPageTree } from './SettingsPageTree';

const AsmDisabledItems = ['applicationTemplate', 'settings.mail'];

const filterButtonsPermissions = (buttons: ISettingsButton[], layoutDomain: IMainLayoutDomainStore) => {
  return buttons.filter((item) => {
    if (ConfigManager.getConfig().extensions.include.includes('asm') && AsmDisabledItems.includes(item.title)) {
      return false;
    }

    return layoutDomain.userHaveAnyAccess(item.permissions);
  });
};

const filterSettingsItems = (settingItems: ISettingsItem[], layoutDomain: IMainLayoutDomainStore) => {
  return settingItems.filter((item) => {
    if (
      ConfigManager.getConfig().extensions.include.includes('asm') &&
      AsmDisabledItems.includes(item.settingsItem.key)
    ) {
      return false;
    }
    return layoutDomain.userHaveAnyAccess(item.settingsItem.permissions);
  });
};

export const SettingsPage = ({ layoutDomain }: IPageWithLayoutProperties) => {
  const [items, setItems] = useState<ISettingsItem[]>([]);

  const container = useContext(ContainerContext);
  const settingsPageExtensions = container.get(SettingsPageExtension);
  const settingsPageTree = container.get(SettingsPageTree);
  const integrationInfoText = container.get(IntegrationSettingsExtension.cardSubtitle);

  useEffect(() => {
    const availableItems = filterSettingsItems(Object.values(settingsPageTree), layoutDomain);
    setItems(availableItems);
  }, [settingsPageTree]);

  return (
    <Grid container direction="row" spacing={5}>
      {items.map((item, index) => {
        const buttons = filterButtonsPermissions(item.settingsItem.buttons, layoutDomain);

        if (item.settingsItem.key === 'integration') {
          item.settingsItem.infoText = integrationInfoText;
        }
        return (
          <SettingsItemView
            key={item.settingsItem.key}
            title={item.settingsItem.title}
            buttons={buttons}
            infoText={item.settingsItem.infoText}
            titleDataCy={`setting-title-${index}`}
            infoTextDataCy={`setting-info-text-${index}`}
          />
        );
      })}

      {settingsPageExtensions.map((Component) => {
        return <Component key={Component} layoutDomain={layoutDomain} />;
      })}
    </Grid>
  );
};

interface AdminSettingsItemViewProperties {
  title: string;
  infoText?: string;
  titleDataCy?: string;
  infoTextDataCy?: string;
  buttons: {
    title: string;
    url: string;
    dataCy?: string;
  }[];
}

export const SettingsItemView = ({
  title,
  titleDataCy,
  buttons,
  infoText,
  infoTextDataCy,
}: AdminSettingsItemViewProperties) => {
  const classes = useStyles();
  const { translateAnyway } = useI18n();

  return (
    <Grid item xs={3}>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant="h5" gutterBottom data-cy={titleDataCy}>
            {translateAnyway(title)}
          </Typography>
          {infoText && (
            <Typography variant="body2" component="p" data-cy={infoTextDataCy}>
              {translateAnyway(infoText)}
            </Typography>
          )}
        </CardContent>
        <CardActions>
          <Grid container direction="row">
            {buttons.map((button) => (
              <Grid item key={button.url}>
                <ListItemLink
                  containerClassName={classes.buttonStyle}
                  color={ListItemLinkColor.light}
                  primaryText={translateAnyway(button.title)}
                  url={button.url}
                  dataCy={`${button.url.split('/').join('-').substring(1)}`}
                />
              </Grid>
            ))}
          </Grid>
        </CardActions>
      </Card>
    </Grid>
  );
};
const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
  pos: {
    marginBottom: 12,
  },
  buttonStyle: {
    padding: '8px',
  },
});
