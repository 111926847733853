import { Box, LinearProgress, SxProps, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Theme, Typography } from "@mui/material";
import { styled } from '@mui/material/styles';
import { makeStyles } from "@mui/styles";
import React from 'react';
import { Colors } from '../../../../../../../../../view/design/color/Colors';
import { ICTFRatingForUI } from '../../store/ICTFRatingForUI';

type ICTFRatingTableProps = {
  items: ICTFRatingForUI[]
}

export const CTFRatingTable = React.memo(({ items }: ICTFRatingTableProps) => {
  return <Box sx={{ width: '100%' }}>
    <TableContainer>
      <Table>
        <TableHead>
          <CTFRatingHeader />
        </TableHead>
        <TableBody>
          {items.map((value, index) => {
            return (
              <CTFRaitingRow key={index} item={value} isLast={items.length - 1 === index} />
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  </Box>
});


const useStyles = makeStyles((theme: Theme) => ({
  row: {
    height: '42px'
  },
  headerRow: {
    height: '20px'
  },
  position: {
    width: '47px',
    padding: '0px 0px 0px 16px'
  },
  userName: {
    padding: '0px'
  },
  score: {
    width: '123px',
    padding: '0px 16px 0px 0px',
  },
}))

const SX_FOR_BORDERED: SxProps<Theme> = { border: "none" };

const CTFRatingHeader = () => {
  const styleClasses = useStyles()

  return <TableRow className={styleClasses.headerRow}>
    <TableCell className={styleClasses.position} sx={SX_FOR_BORDERED} />
    <TableCell className={styleClasses.userName} sx={SX_FOR_BORDERED}>
      <Typography color="text.disabled" variant="caption">Пользователи</Typography>
    </TableCell>
    <TableCell className={styleClasses.score} sx={SX_FOR_BORDERED}>
      <Typography color="text.disabled" variant="caption">Баллы CTF</Typography>
    </TableCell>
  </TableRow>
}

type ICTFRaitingRowProps = {
  item: ICTFRatingForUI,
  isLast: boolean,
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: 8,
  width: '41px',
  backgroundColor: Colors.tableRowDisabled
}));

const CTFRaitingRow = ({ item: { score: { number, percent }, position, userName }, isLast }: ICTFRaitingRowProps) => {
  const numberLabel = React.useMemo(() => number === 0 ? '-' : new Intl.NumberFormat('fr-FR').format(number), [number]);
  const styleClasses = useStyles()

  const sx: SxProps<Theme> = React.useMemo(() => isLast ? SX_FOR_BORDERED : {}, [isLast])

  return <TableRow className={styleClasses.row} >
    <TableCell className={styleClasses.position} sx={sx}>
      <Typography color="text.secondary" variant="body1" sx={{
        // color: Colors.mainActive
      }}>{position}</Typography>
    </TableCell>
    <TableCell className={styleClasses.userName} sx={sx}>
      <Typography variant="body2">{userName}</Typography>
    </TableCell>
    <TableCell className={styleClasses.score} sx={sx}>
      <Box sx={{ display: 'flex', flexWrap: 'nowrap', alignItems: 'center', gap: '16px' }}>
        <BorderLinearProgress variant="determinate" value={percent * 100} color='success' />
        <Typography variant="caption">{numberLabel}</Typography>
      </Box>
    </TableCell>
  </TableRow>
}

