import { injectEntityList } from "../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../common/store/base/injectPrimitive";
import { IEntityListStore } from "../../../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../../../common/store/interface/IPrimitiveStore";
import { IDashboardDataItem } from "../../../shared/dashboards/interface/IDashboardDataItem";
import { mapToDashboardData } from "../../utils/mapToDashboardData";

import { IDashboardDomain } from "./IDashboardDomain";
import { IStudyResultsDashboardUI } from "./IStudyResultsDashboardUI";

export class StudyResultsDashboardUI implements IStudyResultsDashboardUI {
  private alreadyBooted: IPrimitiveStore<boolean>;
  dashboardData: IEntityListStore<IDashboardDataItem>;
  isFetching: IPrimitiveStore<boolean>;

  constructor(private dashboardDomain: IDashboardDomain) {
    this.alreadyBooted = injectPrimitive(false);
    this.isFetching = injectPrimitive(false);
    this.dashboardData = injectEntityList<IDashboardDataItem>([]);
  }

  boot = async () =>  {
    if (this.alreadyBooted.value) return;
    this.isFetching.setValue(true);
    try {
      const data = await this.dashboardDomain.getDashboardData();
      this.dashboardData.setList(mapToDashboardData(data));
    } finally {
      this.alreadyBooted.setValue(true)
      this.isFetching.setValue(false);
    }
  }
}