import { IContainer } from '../../../../../common/container/IContainer';
import { injectPrimitive } from '../../../../../common/store/base/injectPrimitive';
import { CRUDServiceType } from '../../../../../service/common/service/CRUDServiceType';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ProjectListDomain } from '../../../../../view/user/page/project/list/store/ProjectListDomain';
import { RestProjectSearchService } from '../service/RestProjectSearchService';
import { IProjectSearchDomainExtended } from './IProjectSearchDomainExtended';

export const extendProjectListDomain = (
  Ref: new (...args: any[]) => ProjectListDomain,
): new (...args: any[]) => IProjectSearchDomainExtended => {
class ProjectSearchDomainExtended extends Ref {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    container: IContainer,
    public searchGPBService = new RestProjectSearchService(CRUDServiceType.admin),
    public searchProjectTermGPB = injectPrimitive('')
  ) {
    super(layoutDomain, container)
  }

  public async triggerSearchGPB(offset: number = 0) {
    const filters = await this.addSearchProjectsFilters();

    this.ui.isLoadingProjects.setValue(true);
    this.ui.projectListOffset.setValue(offset);
    const userProjects = await this.searchGPBService.searchGPBProjects({
        offset: this.ui.projectListOffset.value,
        limit: 20,
        filter: {
          ...filters,
        },
        fields: ['id', 'applicationsCount', 'lastActivity', 'requirementCount', 'state', 'name', 'isUnitedWithApplication', 'unitedApplicationId', 'codeName']
      });
    const viewProjectInfoModels = await this.mapProjectInfoFromServiceToView(userProjects.data);

    if (offset === 0) {
      this.ui.setUserProjects(viewProjectInfoModels);
    } else {
      this.ui.setUserProjects([...this.ui.userProjects.list, ...viewProjectInfoModels]);
    }

    if (this.ui.projectListOffset.value >= this.ui.projectsTotalCount.value || userProjects.data.length < 20) {
      this.ui.isAllProjectsRendered.setValue(true);
    } else {
      this.ui.isAllProjectsRendered.setValue(false);
    }

    this.ui.isLoadingProjects.setValue(false);
  }

  async addSearchProjectsFilters() {
    const filter = this.projectFilters.value;
    filter.name = {
      like: {
        value: this.ui.searchProjectTerm.value,
        caseInsensitive: false,
      },
    };

    return filter;
  }

}
return ProjectSearchDomainExtended
}
