import { Container, CssBaseline, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { useContext } from 'react';

import { useI18n } from '../../../application/language/useI18n';
import { MainLayoutBottomLogoMenu } from '../../../view/layout/main/parts/LeftNavigation/parts/MainLayoutBottomLogoMenu';
import {
  ErrorPageOrganization,
  isShowOnlyEmail,
} from '../../../view/layout/main/parts/LeftNavigation/parts/MainLayoutLeftNavigationTopLogo';
import { ContainerContext } from '../../container/ContainerContext';

const PageServiceNotAvailable = () => {
  const context = useContext(ContainerContext);
  const ReqEduLogoComponent = context.get(MainLayoutBottomLogoMenu);
  const ErrorPageOrganizationContent = context.get(ErrorPageOrganization);
  const isShowOnlyEmailValue = context.get(isShowOnlyEmail)();
  const { translate, getCurrentLanguage } = useI18n();
  const classes = useStyles();

  return (
    <>
      <CssBaseline />
      <Container disableGutters maxWidth={false}>
        <Typography className={classes.background}>
          <Typography className={classes.topIcons} style={{ width: getCurrentLanguage() === 'ru' ? '220px' : '370px' }}>
            <ReqEduLogoComponent />
          </Typography>

          <Typography>
            <img src="/pictures/errorConnection.png" />
          </Typography>

          <Typography variant="h3" className={classes.headlineText}>
            {translate('serviceUnavailable.title')}
          </Typography>

          <Typography variant="h6" className={classes.mainText}>
            {translate('serviceUnavailable.message')}
          </Typography>

          {!isShowOnlyEmailValue && (
            <div className={classes.bottomContainer}>
              <div className={classes.supportText}>
                <div className={classes.supportRegular}>{translate('errorPage.writeUs')}</div>

                <div className={classes.supportBold}>
                  {getCurrentLanguage() === 'ru' ? 'support@startx.team' : 'support@humanfactor.ai'}
                </div>
              </div>

              {getCurrentLanguage() === 'ru' && (
                <div className={classes.supportText}>
                  <div className={classes.supportRegular}>{translate('errorPage.orCall')}</div>

                  <div className={classes.supportBold}>+7 499 677 19 07</div>
                </div>
              )}
            </div>
          )}
          {isShowOnlyEmailValue && (
            <div className={classes.onlyEmailContainer}>
              <div className={classes.supportText}>
                <div className={classes.supportRegular}>{translate('errorPage.writeUs')}</div>

                <div className={classes.supportBold}>ask@humanfactor.ai</div>
              </div>
            </div>
          )}
          <Typography variant="body2" className={classes.footerText}>
            {ErrorPageOrganizationContent(getCurrentLanguage())} {new Date().getFullYear()}
          </Typography>
        </Typography>
      </Container>
    </>
  );
};

export default PageServiceNotAvailable;

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    margin: '0',
    padding: '0',
    background: '#344046 !important',
    width: '100%',
    height: '100vh',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  topIcons: {
    paddingTop: '20px',
    paddingBottom: '30px',
  },
  mainText: {
    width: '480px',
    color: '#FFFFFF',
    textAlign: 'center',
    paddingBottom: '60px',
  },
  headlineText: {
    color: '#FFFFFF',
    fontWeight: 'bold',
    fontSize: '48',
    paddingTop: '80px',
    paddingBottom: '30px',
  },
  footerText: {
    color: '#9C9C9C',
    fontSize: '12px',
    fontWeight: '200',
    position: 'absolute',
    bottom: '30px',
  },
  bottomContainer: {
    display: 'flex',
    maxWidth: '900px',
    width: '100%',
    justifyContent: 'center',
    gap: 16,
  },
  onlyEmailContainer: { display: 'flex', maxWidth: '900px', width: '100%', justifyContent: 'center' },
  supportText: {
    border: '1px solid #47575F',
    borderRadius: '12px',
    width: '100%',
    maxWidth: '438px',
    height: '108px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400,
    fontSize: '24px',
    lineHeight: '32.02px',
  },
  supportBold: {
    color: '#FFF',
  },
  supportRegular: {
    color: '#9C9C9C',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.15px',
  },
}));
