import { useEffect } from "react";
import { inject } from "../../../common/container/inject";
import { UserSystemRoleModelPermissionMap } from "../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap";
import { IPageWithLayoutProperties } from "../../../view/layout/page/IPageWithLayoutProperties";
import { LearningRoutes } from "../constants/LearningRoutes";
import { IRouterService, RouterServiceToken } from "../service/route/IRouterService";


export const useLoadRedirectMainPageByRole = ({ layoutDomain }: IPageWithLayoutProperties) => {
  const { goTo, getActiveUrl } = inject<IRouterService>(RouterServiceToken);

  useEffect(() => {
    const isAdmin = layoutDomain.userHaveAnyAccess([UserSystemRoleModelPermissionMap['global-allow-any']]);
    const activeUrl = getActiveUrl();
    const isCurrentUrlForAdmin = activeUrl.includes(LearningRoutes.LearningAdmin);

    if (!isAdmin && (isCurrentUrlForAdmin || activeUrl === '/')) {
      goTo(LearningRoutes.LearningUser);
    } else if (isAdmin && !isCurrentUrlForAdmin) {
      goTo(LearningRoutes.LearningAdmin);
    }
    layoutDomain.isSelected.setValue('learning');
  }, [layoutDomain, layoutDomain.ui.activeUser.entity.id]);
}