import { Box, Typography } from '@mui/material';
import { observer } from "mobx-react";
import React from 'react';
import { PieCTFChart } from '../../../../design/chart/PieCTFChart';
import { BlockPapper } from '../../../../design/papper/BlockPapper';
import { CountsSolvedDomain } from './store/CountsSolvedDomain';
import { CountsSolvedUI } from './store/CountsSolvedUI';

export const CountsSolved = observer(() => {
  const [domainIU] = React.useState(() => new CountsSolvedUI(new CountsSolvedDomain()));

  return <Box>
    <BlockPapper
      sx={{
        flexDirection: 'column',
        gap: '16px',
        alignItems: 'center',
        height: 264
      }}>
      <Typography variant='h6' sx={{ width: '100%' }}>Соотношение ответов</Typography>
      <PieCTFChart domainUI={domainIU} />
    </BlockPapper>
  </Box>
})