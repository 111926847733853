import { Box, CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect } from 'react';

import { TranslateText } from '../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../application/language/useI18n';
import { IMainLayoutDomainStore } from '../../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { EmptyPage } from '../../../design/emptyPage/EmptyPage';
import { UnitListAdminCard } from './components/UnitListAdminCard';
import adminEmptyPage from './parts/adminEmptyPage.png';
import { UnitListCard } from './parts/UnitListCard';
import { IUnitListAdminPageDomain } from './store/IUnitListAdminPageDomain';

enum FilterValues {
  All = 'all',
  Published = 'published',
  NotPublished = 'notPublished',
}

export interface IUnitListAdminPage {
  layoutDomain: IMainLayoutDomainStore;
}
export const UnitListAdminPage = observer(({ domain }: { domain: IUnitListAdminPageDomain }) => {
  const { translate } = useI18n();

  useEffect(() => {
    domain.loadData();
  }, [domain]);

  const hasUnits = !!domain.ui.filtredGroupedUnits.list.filter((group) => {
    const publishedUnits = group.unitsList.filter((item) => item.isPublished);
    const notPublishedUnits = group.unitsList.filter((item) => !item.isPublished);

    if (domain.ui.filterByIsPublished.value === FilterValues.All) {
      return publishedUnits.length || notPublishedUnits.length;
    } else if (domain.ui.filterByIsPublished.value === FilterValues.NotPublished) {
      return notPublishedUnits.length;
    } else if (domain.ui.filterByIsPublished.value === FilterValues.Published) {
      return publishedUnits.length;
    }
  }).length;

  return (
    <>
      {domain.ui.isLoading.value ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          {domain.ui.unitList.list.length === 0 ? (
            <EmptyPage
              image={adminEmptyPage}
              title={translate("learningAdminPage.createUnitLabel")}
              buttonTitle={translate("learningAdminPage.createUnit")}
              buttonAction={domain.redirectToCreateUnit}
            />
          ) : (
            <Box sx={{ display: 'flex', gap: '16px', marginTop: '48px' }}>
              <Box
                sx={{
                  flex: '0 0 28%',
                  gap: '16px',
                  display: 'flex',
                  flexDirection: 'column',
                  height: 'calc(100vh - 240px)',
                  overflowY: 'scroll',
                }}
              >
                {domain.ui.filtredGroupedUnits.list?.map((group) => {
                  const publishedUnits = group.unitsList.filter((item) => item.isPublished);
                  const notPublishedUnits = group.unitsList.filter((item) => !item.isPublished);

                  if (domain.ui.filterByIsPublished.value === FilterValues.All && group.unitsList.length) {
                    return <UnitListCard key={group.groupName} title={group.groupName} units={group.unitsList} domain={domain} />;
                  } else if (domain.ui.filterByIsPublished.value === FilterValues.Published && publishedUnits.length) {
                    return <UnitListCard key={group.groupName} title={group.groupName} units={publishedUnits} domain={domain} />;
                  } else if (
                    domain.ui.filterByIsPublished.value === FilterValues.NotPublished &&
                    notPublishedUnits.length
                  ) {
                    return <UnitListCard key={group.groupName} title={group.groupName} units={notPublishedUnits} domain={domain} />;
                  }
                })}
              </Box>
              <Box
                sx={{
                  flex: '0 0 70%',
                  display: 'flex',
                  flexDirection: 'column',
                  gap: '8px',
                  paddingRight: '32px',
                  height: 'calc(100vh - 240px)',
                  overflowY: 'scroll',
                }}
                id="units-container"
              >
                {!hasUnits && (
                  <Box
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      color: 'text.secondary',
                      height: '100%',
                    }}
                  >
                    <Typography variant="h5"><TranslateText i18nKey="learningAdminPage.noUnits" /></Typography>
                  </Box>
                )}
                {domain.ui.filtredGroupedUnits.list.map((group) => {
                  const publishedUnits = group.unitsList.filter((item) => item.isPublished);
                  const notPublishedUnits = group.unitsList.filter((item) => !item.isPublished);
                  if (domain.ui.filterByIsPublished.value === FilterValues.All && group.unitsList.length) {
                    return group.unitsList.map((unit) => {
                      return (
                        <UnitListAdminCard
                          handleEdit={domain.onEditUnit}
                          handleDelete={domain.onDeleteUnit}
                          handlePublish={domain.publishUnit}
                          handleUnpublish={domain.unpublishUnit}
                          unit={unit}
                        />
                      );
                    });
                  } else if (domain.ui.filterByIsPublished.value === FilterValues.Published && publishedUnits.length) {
                    return publishedUnits.map((unit) => {
                      return (
                        <UnitListAdminCard
                          handleEdit={domain.onEditUnit}
                          handleDelete={domain.onDeleteUnit}
                          handlePublish={domain.publishUnit}
                          handleUnpublish={domain.unpublishUnit}
                          unit={unit}
                        />
                      );
                    });
                  } else if (
                    domain.ui.filterByIsPublished.value === FilterValues.NotPublished &&
                    notPublishedUnits.length
                  ) {
                    return notPublishedUnits.map((unit) => {
                      return (
                        <UnitListAdminCard
                          handleEdit={domain.onEditUnit}
                          handleDelete={domain.onDeleteUnit}
                          handlePublish={domain.publishUnit}
                          handleUnpublish={domain.unpublishUnit}
                          unit={unit}
                        />
                      );
                    });
                  }
                })}
              </Box>
            </Box>
          )}
        </>
      )}
    </>
  );
});
