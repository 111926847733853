import { injectPrimitive } from '../../../../common/store/base/injectPrimitive';
import { IApplicationDataModel } from '../../../../service/application/data/IApplicationDataModel';
import { IApplicationModel } from '../../../../service/application/entity/IApplicationModel';
import { IApplicationRequirementEntity } from '../../../../service/applicationRequirement/entity/IApplicationRequirementModel';
import { IApplicationRequirementService } from '../../../../service/applicationRequirement/entity/IApplicationRequirementService';
import { RestApplicationRequirementService } from '../../../../service/applicationRequirement/entity/RestRequirementService';
import { CRUDServiceType } from '../../../../service/common/service/CRUDServiceType';
import { IProjectModel } from '../../../../service/project/IProjectModel';
import { IRequirementModel } from '../../../../service/requirement/entity/IRequirementModel';
import { ServiceType, injectRootService } from '../../../../service/RootServiceFactory';
import { UserSystemRoleModelPermissionMap } from '../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { LayoutNotificationType } from '../../../../view/layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { IRiskManagerDataModel } from '../../service/manager/data/IRiskManagerDataModel';
import { RiskManagerQuestionFieldRelationType } from '../../service/question/field/RiskManagerQuestionFieldRelationType';
import { IRiskManagerRequirementThreatLinkModel } from '../../service/requirement/IRiskManagerRequirementThreatLinkModel';
import { RiskManagerRootService } from '../../service/RiskManagerRootService';
import { RiskManagerSystemRolePermissions } from '../../systemRole/RiskManagerSystemRoleExtensions';
import { IRiskManagerProjectInfoDomain } from '../../view/projectRiskInfo/domain/IRiskManagerProjectInfoDomain';

export class RiskManagerReportDomain {
  constructor(
    public rootDomain: IRiskManagerProjectInfoDomain,
    private layoutDomain: IMainLayoutDomainStore,
    private riskManagerService = new RiskManagerRootService('admin'),
    public coreRootService = injectRootService(ServiceType.admin),
    public isHaveAccessAndReport = injectPrimitive(false),
    private applicationRequirementService: IApplicationRequirementService = new RestApplicationRequirementService(CRUDServiceType.admin),
  ) { }

  async setAccessToProjectReport(projectId: string, layoutDomain?: any, rowStatus?: boolean) {
    let rowContextChecker = false;
    if (layoutDomain) {
      rowContextChecker = layoutDomain.userHaveAnyAccess([
        RiskManagerSystemRolePermissions[
        'extension-risk-manager-settings-report'
        ] as unknown as UserSystemRoleModelPermissionMap,
      ]);
    }

    if (rowContextChecker || this.isCanViewReports()) {
      if (rowStatus !== undefined) {
        this.isHaveAccessAndReport.setValue(rowStatus);
      } else {
        const riskManagerData = this.rootDomain.riskManagersData.list.find(
          (riskManagerData) => riskManagerData.targetId === projectId,
        );
        this.isHaveAccessAndReport.setValue(!!riskManagerData);
      }
    }
  }

  isCanViewReports() {
    const isCanViewReports = this.layoutDomain.userHaveAnyAccess([
      RiskManagerSystemRolePermissions[
      'extension-risk-manager-settings-report'
      ] as unknown as UserSystemRoleModelPermissionMap,
    ]);

    return isCanViewReports;
  }

  async downloadRiskManagerJson(projectId: string) {
    const riskManagerData = (
      await this.riskManagerService.manager.data.search({
        limit: 1,
        filter: { targetId: { equal: projectId } },
      })
    ).data[0];

    if (!riskManagerData.isComplete) {
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.warning,
        text: `JSON не может выгружен. Не все ответы для анкеты рисков заполнены.`,
      });

      return;
    }

    const project = await this.coreRootService.project.entity.getById(projectId);
    const projectApplications = (
      await this.coreRootService.application.entity.search({
        filter: { projectId: { equal: project?.id || '' } },
      })
    ).data;
    const projectApplicationsData = (
      await this.coreRootService.application.data.search({
        filter: {
          ids: {
            in: projectApplications.map((item) => item.dataId || ''),
          },
        },
      })
    ).data;
    const applicationRequirement: IApplicationRequirementEntity[] = [];
    for (let app of projectApplicationsData) {
      const appReqs = await this.applicationRequirementService.getAppReqByAppId(app.id || '');
      applicationRequirement.push(...appReqs);
    }

    const requirements = (await this.coreRootService.requirement.entity.search({ limit: 1000000 })).data;
    const threatLinks = (
      await this.riskManagerService.requirement.link.search({
        filter: { requirementId: { any: requirements.map((requirement) => requirement.id || '') } },
      })
    ).data;

    const data = this.getJsonByTemplate(riskManagerData, project, {
      projectApplications,
      projectApplicationsData,
      requirements,
      threatLinks,
      applicationRequirement,
    });

    const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(JSON.stringify(data))}`;
    const link = document.createElement('a');
    link.href = jsonString;
    link.download = `${project.name} - Анкета Рисков.json`;
    link.click();

    await this.riskManagerService.manager.data.downloadReport(riskManagerData.id || '');
  }

  getJsonByTemplate(
    riskManagerData: IRiskManagerDataModel,
    project: IProjectModel,
    applications: {
      projectApplications: IApplicationModel[];
      projectApplicationsData: IApplicationDataModel[];
      requirements: IRequirementModel[];
      threatLinks: IRiskManagerRequirementThreatLinkModel[];
      applicationRequirement: IApplicationRequirementEntity[];
    },
  ) {
    const staticQuestionsData = riskManagerData.staticQuestionsCopy
      .filter((item) => riskManagerData.templateDataCopy?.staticQuestionsIds?.includes(item.id || ''))
      .map((staticQuestion) => {
        const staticQuestionData = riskManagerData.staticQuestionData.data.find(
          (item) => item.staticQuestionId === staticQuestion.id,
        );
        const staticQuestionFieldsData = riskManagerData.questionFieldData.data.filter(
          (item) => item.questionId === staticQuestion.id,
        );
        const allStaticQuestionFields = riskManagerData.questionFieldsCopy.filter(
          (item) =>
            item.relationType === RiskManagerQuestionFieldRelationType.all ||
            item.relationType === RiskManagerQuestionFieldRelationType.static,
        );
        return {
          id: staticQuestion?.id,
          name: staticQuestion.name,
          answerData: staticQuestionData?.value || '',
          fields: allStaticQuestionFields.map((field) => {
            const fieldData = staticQuestionFieldsData.find((item) => item.fieldId === field.id);
            return {
              id: field.id,
              name: field.name,
              description: field.description,
              fieldData: fieldData?.value,
            };
          }),
        };
      });

    const risksData = riskManagerData.risksCopy
      .filter((risk) => riskManagerData.templateDataCopy?.risksIds?.includes(risk.id || ''))
      .map((risk) => {
        const riskLevelData = riskManagerData.riskLevelsData.data.find(
          (riskLevelData) => riskLevelData.riskId === risk.id,
        );
        const riskLevel = riskManagerData.templateDataCopy?.riskLevelsSettings.levels.find(
          (level) => level.pseudoId === riskLevelData?.riskLevelSettingsPseudoId || '',
        );

        return {
          id: risk.id,
          name: risk.name,
          description: risk.description,
          riskAnswersValue: riskLevelData?.value || 0,
          riskLevel: {
            id: riskLevel?.pseudoId || '',
            name: riskLevel?.name || '',
            description: riskLevel?.description || '',
          },
        };
      });

    const questionsData = riskManagerData.questionsCopy
      .filter((question) => riskManagerData.templateDataCopy?.questionsIds?.includes(question.id || ''))
      .map((question) => {
        const questionData = riskManagerData.answersData.data.find((answer) => answer.questionId === question.id);
        const answers = question.answersMap.answers.filter(
          (answer) =>
            questionData?.answerPseudoIds.includes(answer.pseudoId || '') ||
            questionData?.answerPseudoId === answer.pseudoId,
        );
        const questionFields = riskManagerData.questionFieldsCopy.filter(
          (item) =>
            item.relationType === RiskManagerQuestionFieldRelationType.all ||
            item.relationType === RiskManagerQuestionFieldRelationType.answers,
        );
        const questionFieldsData = riskManagerData.questionFieldData.data.filter(
          (item) => item.questionId === question.id,
        );
        return {
          question: {
            id: question.id,
            text: question.text,
          },
          answers: answers.map((answer) => ({
            id: answer.pseudoId,
            name: answer.text,
            settings: riskManagerData.templateDataCopy?.answersValueToRiskData.valueData
              .filter(
                (answerToRisk) => answerToRisk.answerId === answer.pseudoId && answerToRisk.questionId === question.id,
              )
              .map((answerToRisk) => ({
                riskId: answerToRisk.riskId,
                questionId: answerToRisk.questionId,
                answerId: answerToRisk.answerId,
                value: answerToRisk.value,
              })),
          })),
          fields: questionFields.map((field) => {
            const fieldData = questionFieldsData.find((item) => item.fieldId === field.id);
            return {
              id: field.id,
              name: field.name,
              description: field.description,
              fieldData: fieldData?.value,
            };
          }),
        };
      });

    const applicationsData = applications.projectApplicationsData.map((applicationData) => {
      const applicationRequirementsEntities = applications.requirements.filter((requirement) => {
        const requirements = applicationData.savedRequirementsIds?.map((reqId) => {
          return applications.applicationRequirement.find((appReq) =>
            appReq.id === reqId && appReq.applicationDataId === applicationData.id
          )?.requirementId;
        })
        return requirements?.includes(requirement.id || '');
      });

      const applicationReport = {
        requirements: applicationRequirementsEntities.map((requirement) => ({
          id: requirement.id,
          shortName: requirement.shortName,
          description: requirement.description,
          categoryId: requirement.categoryId,
          risksIds: applications.threatLinks
            .filter((link) => link.requirementId === requirement.id)
            .map((link) => link.risksMap.risksIds),
        })),
      };

      return applicationReport;
    });

    return {
      dates: {
        firstCreateDate: new Date(riskManagerData.createDate || 0).toISOString(),
        lastUpdateDate: new Date(riskManagerData.lastUpdateDate || 0).toISOString(),
        downloadDate: new Date().toISOString(),
      },
      project: {
        name: project.name,
        id: project.id,
      },
      riskManager: {
        staticQuestions: staticQuestionsData,
        questions: questionsData,
        risks: risksData,
      },
      applications: applicationsData,
    };
  }
}
