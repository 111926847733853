import { ILocalization } from '../../../../../application/language/ILocalization';
import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { AdminLogsTable } from '../AdminLogsTable';

type IArgs = {
  i18n: ILocalization
}

export const AdminLogsRouter = ({ i18n: { translate } }: IArgs) => ({
  path: '/settings/logs',
  pageTitle: translate('phrases.logging'),
  component: AdminLogsTable,
  allowedPermissions: [UserSystemRoleModelPermissionMap['plugin-settings-logger-is-allow']],
});
