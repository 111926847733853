import { colors } from "@mui/material";
import { injectEntityList } from "../../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../../common/store/base/injectPrimitive";
import { IEntityListStore } from "../../../../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../../../../common/store/interface/IPrimitiveStore";
import { Colors } from "../../../../../../../../view/design/color/Colors";
import { IUsersRatingWithRange } from "../../../../../../service/rating/interface/IUsersRatingWithRange";
import { IUsersRangeResultsDomain } from "./IUsersRangeResultsDomain";
import { IGroupMeta, IRangeDataForRecharts, IUsersResultsRangeUI } from "./IUsersResultsRangeUI";

const GROUPS_META: Record<string, IGroupMeta> = {
  'Не начали': {
    color: colors.grey[600],
    dataKey: 'notStarted'
  },
  'В процессе': {
    color: colors.amber[400],
    dataKey: "inProgress"
  },
  'Завершили': {
    color: Colors.positiveActive,
    dataKey: "completed"
  }
}

export class UsersResultsRangeUI implements IUsersResultsRangeUI {
  private alreadyBooted: IPrimitiveStore<boolean>;
  dataRanges: IEntityListStore<IRangeDataForRecharts>;
  isFetching: IPrimitiveStore<boolean>;
  groupsMeta: Record<string, IGroupMeta> = GROUPS_META;

  constructor(
    private usersRangeResultsDomain: IUsersRangeResultsDomain
  ) {
    this.alreadyBooted = injectPrimitive(false);
    this.isFetching = injectPrimitive(false);
    this.dataRanges = injectEntityList<IRangeDataForRecharts>([]);
  }


  boot = async () =>  {
    if (this.alreadyBooted.value) return;
    this.isFetching.setValue(true);
    try {
      const ranges = await this.usersRangeResultsDomain.loadRatingWithRange();
      this.alreadyBooted.setValue(true)
      this.dataRanges.setList(this.restRangeToRechartsDTO(ranges));
    } finally {
      this.isFetching.setValue(false);
    }
  }

  private restRangeToRechartsDTO = (data: IUsersRatingWithRange[]): IRangeDataForRecharts[] => data.map(({ from, to , counts }, index) => {
    const label = from === to ? `${from}` : `${from}–${to}`

    if (index === 0) return {
      name: label,
      notStarted: counts,
      inProgress: 0,
      completed: 0,
      total: `${counts}`
    }

    if (index === data.length - 1) return {
      name: label,
      notStarted: 0,
      inProgress: 0,
      completed: counts,
      total: `${counts}`
    }

    return {
      name: label,
      notStarted: 0,
      inProgress: counts,
      completed: 0,
      total: `${counts}`
    };
  })
}