import { Box } from '@mui/material';
import React from 'react';

import { useI18n } from '../../../../../application/language/useI18n';
import { AppSecLogo } from '../../../../design/icon/AppSecLogo';
import { HumanFactorLogo } from '../../../../design/icon/HumanFactorLogo';
import { REQIcon } from '../../../../design/icon/REQIcon';
import { StartIcon } from '../../../../design/icon/StartIcon';

export const BottomLogoMenu = ({
  iconStyle,
  containerStyle,
}: {
  iconStyle?: Record<string, any>;
  containerStyle?: Record<string, any>;
}) => {
  const { getCurrentLanguage } = useI18n();

  return (
    <Box
      sx={
        containerStyle
          ? containerStyle
          : { padding: '18px 21px 18px 16px', display: 'flex', justifyContent: 'center', gap: 2 }
      }
    >
      {getCurrentLanguage() === 'ru' ? <StartIcon /> : <HumanFactorLogo style={iconStyle} />}
      {getCurrentLanguage() === 'ru' ? <REQIcon /> : <AppSecLogo style={iconStyle} />}
    </Box>
  );
};