import { ConfigNotLoadFromServerError } from "./error/ConfigNotLoadFromServerError";
import { ICTFServerConfig } from "./ICTFServerConfig";

export class CTFConfigModule {
  static serverConfig: ICTFServerConfig = {
    ctfDateStart: Infinity,
    ctfApplicationLink: undefined,
    ctfCTFdLink: undefined,
    ctfVideoLink: undefined,
  }


  static getBaseAPIUrl = () => `${window.location.protocol}//${window.location.host}/v2/api/ctf/`;

  static async loadConfigFromBackend () {
    const response = await fetch(this.getBaseAPIUrl()+ `config`);

    if (!response.ok) {
      throw new ConfigNotLoadFromServerError();
    }
    const config = await response.json();

    CTFConfigModule.serverConfig = config;
  }
}