import { IProjectFieldsMapValueType } from './../../../../../../service/project/IProjectFieldsMap';
import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { inject } from '../../../../../../common/container/inject';
import { IProjectFieldsMap } from '../../../../../../service/project/IProjectFieldsMap';
import { IProjectService } from '../../../../../../service/project/IProjectService';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { FormDomain } from '../../../../../admin/page/form/store/FormDomain';
import { LayoutNotificationType } from '../../../../../layout/common/notification/store/ILayoutNotification';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { ProjectFormModel } from './ProjectFormModel';
import { ProjectFormUI } from './ProjectFormUI';
import { IError } from '../../../../../../common/error/IError';
import { ApplicationDetailDomain } from '../../../application/info/store/ApplicationDetailDomain';
import { injectEntity } from '../../../../../../common/store/base/injectEntity';
import { ILocalization, ILocalizationToken } from '../../../../../../application/language/ILocalization';
import { injectEntityList } from '../../../../../../common/store/base/injectEntityList';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { IApplicationModel } from '../../../../../../service/application/entity/IApplicationModel';

export class ProjectFormDomain extends FormDomain<ProjectFormModel, IProjectService> {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    protected rootService = injectRootService(layoutDomain.serviceType.value),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    public ui = new ProjectFormUI(),
    public applicationDomain = injectEntity<ApplicationDetailDomain | null>(null),
    public projects = injectEntityList<IProjectModel>([]),
    public applications = injectEntityList<IApplicationModel>([]),
    private i18n: ILocalization = inject<ILocalization>(ILocalizationToken)
  ) {
    super(layoutDomain, rootService.project.entity);
  }

  loadData = async (id: string | null = null) => {
    await super.loadData(id);
    await this.loadDependencies();
    // if (!id) {
    await this.setFieldsMap();
    await this.setPermissions();
    if (!id && this.ui.isCanUseUnitedForm.value) {
      this.ui.model.entity.isUnitedWithApplication =
        ConfigManager.getConfig().ui.project.form.isUnitedWithApplicationByDefault;
    }
    // }
    const projects = (await this.rootService.project.entity.search({ limit: 100000 })).data
    const applications = (await this.rootService.application.entity.search({ limit: 100000, filter: {} })).data

    this.projects.setList(projects)
    this.applications.setList(applications)
  };

  async setPermissions() {
    const isCanCreateProjects = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-project'],
    ]);
    const isCanCreateApplications = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-application'],
    ]);

    this.ui.isCanUseUnitedForm.setValue(isCanCreateProjects && isCanCreateApplications);
  }

  setCustomValue(name: string, value: any) {
    this.ui.model.entity.customFieldsValues[name] = value;
  }

  async setFieldsMap() {
    const fieldsMap: IProjectFieldsMap = {
      fields: (ConfigManager.getConfig().ui?.project?.form?.settings?.length || 0) > 0 ?
        ConfigManager.getConfig().ui?.project?.form?.settings : this.getDefaultFormSettings(),
    };
    if (
      !fieldsMap.fields.find((item) => item.values.find((item) => item.type === IProjectFieldsMapValueType.rolesMap))
    ) {
      fieldsMap.fields.push({
        values: [
          {
            type: IProjectFieldsMapValueType.rolesMap,
            description: ' ',
            name: 'rolesMap',
            title: this.i18n.translate('phrases.projectUser'),
          },
        ],
      });
    }

    this.ui.model.entity.fieldsMap = fieldsMap;
    this.ui.model.entity.customFieldsValues = {};
  }

  private getDefaultFormSettings() {
    return [
      {
        values: [
          {
            name: 'name',
            type: IProjectFieldsMapValueType.name,
            description: this.i18n.translate('createProject.nameSubtitle'),
            title: this.i18n.translate('createProject.nameTitle'),
          },
        ],
      },
      {
        values: [
          {
            name: 'codeName',
            type: IProjectFieldsMapValueType.codeName,
            description: this.i18n.translate('createProject.idSubtitle'),
            title: this.i18n.translate('createProject.idTitle'),
          },
        ],
      },
      {
        values: [
          {
            name: 'jiraLink',
            type: IProjectFieldsMapValueType.jiraLink,
            description: this.i18n.translate('createProject.jiraLinkSubtitle'),
            title: this.i18n.translate('createProject.jiraLinkTitle'),
          },
        ],
      },
      {
        values: [
          {
            type: IProjectFieldsMapValueType.comment,
            description: this.i18n.translate('createProject.summarySubtitle'),
            name: 'comment',
            title: this.i18n.translate('createProject.summaryTitle'),
          },
        ],
      },
    ];
  }

  getUpdateCustomFieldHandler(fieldName: string) {
    return (newValue: any) => {
      this.ui.model.entity.customFieldsValues[fieldName] = newValue;
    };
  }

  getCustomMultiSelectFieldHandler(fieldName: string) {
    return (newValueList: { value?: string }[]) => {
      this.ui.model.entity.customFieldsValues[fieldName] = newValueList.map((value) => value.value);
    };
  }

  loadDependencies = async () => {
    try {
      this.ui.isLoading.setValue(true);

      const users = await this.rootService.user.entity.search({
        limit: 100000,
        filter: { isActive: true },
      });
      const projectRoles = await this.rootService.projectRole.entity.search({
        limit: 100000,
        filter: { isActive: true },
      });
      this.ui.projectRoles.setList(projectRoles.data);
      this.ui.users.setList(users.data);

      this.ui.isLoading.setValue(false);
    } catch (error) {
      return this.errorsHandler(error as IError<any>);
    }
  };

  async save(redirect: boolean = true) {
    const isNew = !this.ui.model.entity.id;
    await this.removeValidationErrors();
    const result = await super.save();
    if (this.ui.validationErrors.list.length === 0) {
      const notificationText = isNew ? 'Создан проект: ' : 'Проект обновлён: ';
      this.layoutDomain.notifications.showNotification({
        type: LayoutNotificationType.success,
        text: `${notificationText} ${this.ui.model.entity.name}`,
      });
      if (redirect) {
        this.redirectAfterSave();
      }
    }
    return result;
  }

  protected redirectAfterSave() {
    this.router.goTo(`/project/${this.ui.model.entity.id}/info`);
  }

  cancelEdit = async () => {
    this.router.goBack();
  };

  async validateProjectName(event) {
    this.ui.validationErrors.setList([])
    const handler = this.getUpdateFieldHandler('name')
    handler(event)
    const projects = this.projects.list
    const entity = this.ui.model.entity
    let currentNameInApplication
    let currentNameInProject
    if (entity.isUnitedWithApplication || entity?.unitedApplicationId) {
      const applications = this.applications.list
      currentNameInApplication = applications.find((item) => item.name?.toLocaleLowerCase() === this.ui.model?.entity?.name?.toLocaleLowerCase())
      currentNameInProject = projects.find((item) => item.name?.toLocaleLowerCase() === this.ui.model?.entity?.name?.toLocaleLowerCase())
    } else {
      currentNameInProject = projects.find((item) => item.name?.toLocaleLowerCase() === this.ui.model?.entity?.name?.toLocaleLowerCase())
    }

    if (!(entity.id === currentNameInProject?.id && entity?.name === currentNameInProject?.name)) {
      if (currentNameInProject && currentNameInApplication) {
        this.ui.validationErrors.setList([...this.ui.validationErrors.list, {
          target: 'name',
          //todo сделать перевод
          message: 'Проект или Система с таким названием уже существует',
        }])
      } else if (currentNameInProject) {
        this.ui.validationErrors.setList([...this.ui.validationErrors.list, {
          target: 'name',
          //todo сделать перевод
          message: 'Проект с таким названием уже существует',
        }])

      } else if (currentNameInApplication) {
        this.ui.validationErrors.setList([...this.ui.validationErrors.list, {
          target: 'name',
          //todo сделать перевод
          message: 'Система с таким названием уже существует',
        }])
      }
    }
  }
}
