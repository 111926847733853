import { loader } from '@monaco-editor/react';
import { LicenseInfo } from '@mui/x-data-grid-pro';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';

import { StyledEngineProvider } from '@mui/material';
import Application from './application/Application';
import ApplicationRouter from './application/ApplicationRouter';
import { ConfigLoader } from './application/config/ConfigLoader';
import I18nProvider from './application/language/LocalizationProvider';
import ApplicationTheme from './application/theme/ApplicationTheme';
import ErrorBundary from './common/errorPage/ErrorBoundary/ErrorBundary';
import * as serviceWorker from './serviceWorker';

export const cacheDb: IDBOpenDBRequest = indexedDB.open('cache', 1);

loader.config({ paths: { vs: '/monaco-editor/min/vs' } });
LicenseInfo.setLicenseKey(
  '1c01c2fe28dbe5f5e6f9be5cb1a6e7bfTz04OTY3NCxFPTE3NDYzNTQzOTQwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);

ReactDOM.render(
  <Suspense fallback={<div>Loading...</div>}>
    <I18nProvider>
      <StyledEngineProvider injectFirst>
        <ApplicationTheme>
          <ErrorBundary>
            <Application>
              <ConfigLoader>
                <ApplicationRouter />
              </ConfigLoader>
            </Application>
          </ErrorBundary>
        </ApplicationTheme>
      </StyledEngineProvider>
    </I18nProvider>
  </Suspense>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
