import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { inject } from '../../../../../common/container/inject';
import { IRouterService, RouterServiceToken } from '../../../../../service/route/IRouterService';
import { IMainLayoutDomainStore } from '../../../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { ApplicationTheme } from '../../../../learning/application/theme/ApplicationTheme';
import { CTFRoutes } from '../../../service/router/CTFRoutes';
import { RaitingBaseDomain } from './parts/CTFRatingBlock/store/RaitingBaseDomain';
import { SmallCTFLinks } from './parts/SmallCTFLinks';
import { CTFRatingPage } from './Rating/CTFRatingPage';
import { StatisticCTFPage } from './Statistic/StatisticCTFPage';

type Props = {
  layoutDomain: IMainLayoutDomainStore;
};

const getDefaultCurrentPage = () => inject<IRouterService>(RouterServiceToken).getActiveUrl();

const useCTFAdminRouter = () => {
  const history = useHistory();
  const [currentTab, setCurrentValue] = useState(() => getDefaultCurrentPage());
  const handleChangeTab = (event, newValue) => {
    setCurrentValue(newValue);
    history.push(newValue);
  };

  return {
    currentTab,
    handleChangeTab,
  }
}
export const CTFMainPage = observer(({ layoutDomain }: Props) => {
  const { currentTab, handleChangeTab } = useCTFAdminRouter()

  const [raingDomain] = React.useState(new RaitingBaseDomain())

  return <ApplicationTheme>
    <Box
      sx={{
        height: 'calc(100vh - 48px)',
        overflowY: 'hidden'
      }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
          marginBottom: '24px',
          paddingBottom: '24px',
        }}
      >
        <Typography variant="h4" sx={{ paddingLeft: '16px' }}>
          CTF
        </Typography>
        <SmallCTFLinks />
      </Box>
      <Box sx={{
        width: '100%',
        typography: 'body1',
        height: 'calc(100% - 67px)',
        overflowY: 'hidden'
      }}>
        <TabContext value={currentTab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', justifyContent: 'space-between' }}>
            <TabList onChange={handleChangeTab}>
              <Tab sx={{ textTransform: 'capitalize' }} label='Рейтинг' value={CTFRoutes.ctfAdminPage} />
              <Tab sx={{ textTransform: 'capitalize' }} label='Статистика' value={CTFRoutes.ctfAdminStatisticPage} />
            </TabList>
          </Box>
          <Box sx={{
            height: 'calc(100% - 50px)',
            overflowY: 'auto'
          }}>
            <TabPanel value={CTFRoutes.ctfAdminPage} sx={{ padding: '24px 0px' }}>
              <CTFRatingPage raingDomain={raingDomain} />
            </TabPanel>
            <TabPanel value={CTFRoutes.ctfAdminStatisticPage} sx={{ padding: '24px 0px' }}>
              <StatisticCTFPage />
            </TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  </ApplicationTheme>
}
)
