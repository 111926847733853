
import { Box, Button, Link } from '@mui/material';
import React, { memo } from 'react';
import { CTFLinksUI } from './store/CTFLinksUI';
import { CTFLinkButtonContent } from './store/ICTFLinksUI';

const LinkButton = memo(({ Icon, link, label }: CTFLinkButtonContent) => {
  if (!link) return null;

  return (<Link href={link} target="_blank" rel="noopener noreferrer">
    <Button variant='outlined' startIcon={<Icon />} color='secondary'>
      {label}
    </Button>
  </Link>)
})

export const SmallCTFLinks = memo(() => {
  const [{ btnsContent }] = React.useState(new CTFLinksUI());

  return (
    <Box
      sx={{
        display: 'flex',
        gap: '8px'
      }}
    >
      {btnsContent.map(content => <LinkButton {...content} key={content.link} />)}
    </Box>
  )
});