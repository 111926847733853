import { Box, CircularProgress, List, ListItem, Typography } from "@mui/material";
import { observer } from "mobx-react";
import React from "react";
import { Cell, Pie, PieChart, ResponsiveContainer } from "recharts";
import { IEntityListStore } from "../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../common/store/interface/IPrimitiveStore";

export type IPieColvedCountRange<NamesOfGroup extends string> = {
  name: NamesOfGroup;
  value: number;
  color: string;
  label: string;
};

export type IBasePieUI<NamesOfGroup extends string> = {
  data: IEntityListStore<IPieColvedCountRange<NamesOfGroup>>;
  isFetching: IPrimitiveStore<boolean>;
  loadData: () => Promise<void>;
}

export type IPieCTFChartProps<NamesOfGroup extends string> = {
  domainUI: IBasePieUI<NamesOfGroup>;
}
export const PieCTFChart = observer(<NamesOfGroup extends string>({ domainUI: {
  loadData, isFetching, data
} }: IPieCTFChartProps<NamesOfGroup>) => {
  React.useEffect(() => {
    loadData()
  }, [])


  return <Box sx={{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
  }}>
    {isFetching.value ? <CircularProgress /> :
      <React.Fragment>
        <ResponsiveContainer width="100%" height="100%">
          <PieChart>
            <Pie data={data.list} dataKey="value" startAngle={90} endAngle={450} innerRadius={50} outerRadius={80} paddingAngle={1}>
              {data.list.map(({ color }) => (<Cell key={color} fill={color} stroke='iniherit' />))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>

        <Box height="100%">
          <List disablePadding sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '16px',
            alignItems: 'start'
          }} >
            {
              data.list.map(({ label, color }, index) => (
                <ListItem
                  sx={{ display: 'inline-flex', width: 'auto', color: color, gap: '8px', alignItems: 'baseline' }}
                  disablePadding
                  key={`item-${index}`}><Box sx={{
                    width: '12px',
                    height: '12px',
                    borderRadius: '2px',
                    aspectRatio: '1 / 1',
                    background: color,
                  }} />
                  <Typography>
                    {label}
                  </Typography> </ListItem>
              ))
            }
          </List>
        </Box>
      </React.Fragment>
    }
  </Box>
})

