import { ListItem, ListItemIcon, ListItemText } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { ReactNode } from 'react';
import { Link } from 'react-router-dom';

export interface IListItemLinkProperties {
  itemKey?: string;
  url: string;
  primaryText: string;
  secondaryText?: string;
  icon?: any;
  color?: ListItemLinkColor;
  containerClassName?: string;
  primaryTextClassName?: string;
  secondaryTextClassName?: string;
  dataCy?: string;
  isSelected?: boolean;
  after?: ReactNode;
  onClick?: () => void;
}

export enum ListItemLinkColor {
  default = 'default',
  light = 'light',
  danger = 'danger',
}

export const ListItemLink = ({
  color = ListItemLinkColor.default,
  itemKey,
  url,
  primaryText,
  secondaryText,
  icon = null,
  containerClassName = '',
  primaryTextClassName = '',
  secondaryTextClassName = '',
  dataCy,
  isSelected,
  after,
  onClick = () => { },
}: IListItemLinkProperties) => {
  const colorToStyleMap: any = {
    [ListItemLinkColor.default]: {},
    [ListItemLinkColor.light]: { color: '#b3e5fc' },
    [ListItemLinkColor.danger]: { color: '#ff8a65' },
  };
  const classes = useStyles();

  let linkTextStyle = colorToStyleMap[color] || colorToStyleMap[ListItemLinkColor.default];
  return (
    <ListItem
      className={isSelected ? classes.selected : containerClassName}
      style={linkTextStyle}
      key={itemKey}
      button
      component={Link}
      to={url}
      data-cy={dataCy}
      onClick={() => {
        onClick();
      }}
    >
      {icon && <ListItemIcon children={icon} />}
      <ListItemText
        primaryTypographyProps={{ className: primaryTextClassName }}
        secondaryTypographyProps={{ className: secondaryTextClassName }}
        primary={primaryText}
        secondary={secondaryText}
      />
      {after ?? null}
    </ListItem>
  );
};

const useStyles = makeStyles(() => ({
  selected: {
    backgroundColor: '#90CAF929',
  },
}));
