import { observer } from 'mobx-react';
import * as React from 'react';

import { useI18n } from '../../../../application/language/useI18n';
import { FormTextField } from '../../../../view/design/form/FormTextField';

export const ProjectFormFieldCodeNameExtended = observer(({ domain, uiEntity, fieldValue }: any) => {
  const { translate } = useI18n();

  const categoryShortName = domain.applicationDomain.entity?.ui.editSpecificationsCategories.list.find((category) => category.name === 'b. Краткое название АС');
  const categoryRegistryNumber = domain.applicationDomain.entity?.ui.editSpecificationsCategories.list.find((category) => category.name === 'c. Номер АС в Реестре АС');
  const shortNameTextField = domain.applicationDomain.entity?.ui.specificationsTextFieldsValues.list.find((item) => item.categoryId === categoryShortName?.id);
  const regestryNumberTextField = domain.applicationDomain.entity?.ui.specificationsTextFieldsValues.list.find((item) => item.categoryId === categoryRegistryNumber?.id);

  let codeNameText = '';
  if (shortNameTextField) {
    codeNameText += shortNameTextField.name;
  }
  if (regestryNumberTextField) {
    codeNameText += shortNameTextField?.name ? '/' + regestryNumberTextField.name : regestryNumberTextField.name;
  }
  uiEntity.codeName = codeNameText;
  return (
    <FormTextField
      disabled={true}
      errorMessage={domain.getValidationErrorFor('codeName')?.message}
      value={uiEntity.codeName}
      onChange={domain.getUpdateFieldHandler('codeName')}
      label={fieldValue.title || 'Код'}
      helperText={'Данное поле содержит краткое название АС и номер в Реестре АС'}
      required={false}
      inputProps={{
        'data-cy': 'project-product-id',
      }}
    />
  );
});

