import { Paper, PaperTypeMap } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import React from "react"

export const BlockPapper = (props: DefaultComponentProps<PaperTypeMap>) => <Paper {...props}
  sx={{
    background: 'rgba(255, 255, 255, 0.04)',
    padding: '24px',
    borderRadius: '8px',
    width: '100%',
    height: '100%',
    display: 'flex',
    ...props?.sx,
  }}
/>