import { Box, TextField, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { TranslateText } from '../../../../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../../../../application/language/useI18n';
import { IUnitDomain } from '../../../store/IUnitDomain';

interface IPassageTimeAwards {
  unitDomain: IUnitDomain;
}

export const PassageTimeAward = observer(({ unitDomain }: IPassageTimeAwards) => {
  const { translate } = useI18n();
  return (
    <Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <Typography variant="h6">
          <TranslateText i18nKey={'passingResults.completionTime'} />
        </Typography>
        <Typography variant="body1">
          <TranslateText i18nKey={'passingResults.completionTimeDescription'} />
        </Typography>
        <TextField
          label={translate('phrases.minutes')}
          variant={'standard'}
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={unitDomain.ui.unit.entity.quiz.passageTime}
          onChange={(event) => {
            unitDomain.ui.unit.entity.quiz.passageTime = event.target.value;
          }}
        />
      </Box>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '16px', marginTop: '66px' }}>
        <Typography variant="h6">
          <TranslateText i18nKey={'phrases.award'} />
        </Typography>
        <Typography variant="body1">
          <TranslateText i18nKey={'passingResults.awardDescription'} />
        </Typography>
      </Box>
    </Box>
  );
});
