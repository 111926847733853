import { IRestCTFStatisticService } from "../../../../../../service/statistc/IRestCTFStatisticService";
import { RestCTFStatisticService } from "../../../../../../service/statistc/RestCTFStatisticService";
import { IChallengesByGroupDomain } from "./IChallengesByGroupDomain";

export class ChallengesByGroupDomain implements IChallengesByGroupDomain  {
  private restApi: IRestCTFStatisticService;

  constructor() {
    this.restApi = new RestCTFStatisticService();
  }

  getData = () => this.restApi.getSolvedChallengesByCategoryService()
}