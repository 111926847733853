import { GetApp } from '@mui/icons-material';
import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useState } from 'react';
import { useI18n } from '../../../../../../application/language/useI18n';
import { FullStudyReportDomain } from './store/FullStudyReportDomain';
import { StudyReportNavbarUI } from './store/StudyReportNavbarUI';

export const StudyReportNavbar = observer(() => {
  const { translate } = useI18n();

  const [{ isFetching, handleDownload }] = useState(() => new StudyReportNavbarUI(new FullStudyReportDomain()))

  return <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
    <Button disabled={isFetching.value} variant="outlined" startIcon={<GetApp />} onClick={handleDownload}>
      {translate('analytics.exportReports')}
    </Button>
  </Box>
})
