import { ILocalization } from "../../application/language/ILocalization";

export const serverToLocalDate = (serverDate: Date | null) => {
  if (!serverDate) {
    return serverDate;
  }
  return serverDate;
};

export const getServerNowDate = () => {
  const newLocalDate = new Date();
  // const offsetInMs = newLocalDate.getTimezoneOffset() * 60 * 1000 * -1;
  // return new Date(newLocalDate.getTime() - offsetInMs);

  return newLocalDate;
};

export const timeFormat = (date?: Date | null) => {
  if (!date) return '';
  // return `${date.getHours()}:${date.getMinutes()} ${date.getDay()}.${date.getMonth()}.${date.getFullYear()}`;
  return `${date.toLocaleTimeString()} ${date.toLocaleDateString()}`;
};

export const riskTimeFormat = (date?: number | null) => {
  if (!date) return '';
  return `${new Date(date).toLocaleDateString()} ${new Date(date).toLocaleTimeString().slice(0, -3)}`;
};

export const timeSince = (activityDate?: Date | null, i18n?: ILocalization): string | undefined => {
  if (!activityDate) {
    return i18n?.translate('phrases.never');
  }
  activityDate = new Date(activityDate);
  const nowDate = new Date();

  const secondsAgo = Math.floor((nowDate.getTime() - activityDate.getTime()) / 1000);

  const dayInSeconds = 86400;
  const hoursInSeconds = 3600;
  const minutesInSeconds = 60;

  let daysInterval = Math.floor(secondsAgo / dayInSeconds);

  if (daysInterval >= 1 && daysInterval < 2) {
    return i18n?.translate('phrases.yesterday');
  }

  if (daysInterval >= 1) {
    const dayName = declOfNum(daysInterval, i18n?.i18n.language === 'en' ? ['day', 'days', 'days'] : ['день', 'дня', 'дней']);

    return `${daysInterval} ${dayName} ${String(i18n?.translate('phrases.ago')).toLowerCase()}`;
  }

  const hoursInterval = Math.floor(secondsAgo / hoursInSeconds);

  if (hoursInterval >= 1) {
    const hoursName = declOfNum(hoursInterval, i18n?.i18n.language === 'en' ? ['hour', 'hours', 'hours'] : ['час', 'часа', 'часов']);
    return `${hoursInterval} ${hoursName} ${String(i18n?.translate('phrases.ago')).toLowerCase()}`;
  }

  const minutesInterval = Math.floor(secondsAgo / minutesInSeconds);
  if (minutesInterval >= 1 && minutesInterval < 2) {
    return String(i18n?.translate('phrases.minuteAgo')).toLowerCase();
  }
  if (minutesInterval >= 2) {
    const dayName = declOfNum(minutesInterval, i18n?.i18n.language === 'en' ? ['minute', 'minutes', 'minutes'] : ['минута', 'минуты', 'минут']);
    return `${minutesInterval} ${dayName} ${String(i18n?.translate('phrases.ago')).toLowerCase()}`;
  }

  return i18n?.translate('phrases.now');
};

const declOfNum = (number: number, titles: string[]) => {
  var cases = [2, 0, 1, 1, 1, 2];
  return titles[number % 100 > 4 && number % 100 < 20 ? 2 : cases[number % 10 < 5 ? number % 10 : 5]];
};
