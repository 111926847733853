import { Box, CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';
import { Colors } from '../../../../../../learning/view/design/colors/Colors';
import { BlockPapper } from '../../../../design/papper/BlockPapper';
import { CommonStatisticDomain } from './store/CommonStatisticDomain';
import { CommonStatisticUI } from './store/CommonStatisticUI';
import { IStatisticBlock } from './store/IStatisticBlock';

const Block = ({ count, title, label }: IStatisticBlock) => <Box sx={{
  width: '100%'
}}>
  <BlockPapper sx={{
    flexDirection: 'column',
    gap: '8px',
  }}
    elevation={0}
  >
    <Typography variant='body2'>{title}</Typography>
    <Box sx={{
      display: 'flex',
      gap: '8px',
      flexDirection: 'row',
      alignItems: 'center',
    }}>
      <Box
        sx={{
          background: Colors.blackOpacity('.08'),
          borderRadius: '52px',
          minWidth: '39px',
          padding: '0px 10px',
          height: '36px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }} >
        <Typography variant='h6'>{count}</Typography>
      </Box>
      <Typography variant='body2'>{label}</Typography>
    </Box>
  </BlockPapper>
</Box>

export const CommonStatistic = observer(() => {
  const [{ loadData, blocksContent, isFetching }] = React.useState(() => new CommonStatisticUI(new CommonStatisticDomain()))

  React.useEffect(() => {
    loadData();
  }, [])

  return <Box
    sx={{ display: 'flex', gap: '8px', flexDirection: 'row', minHeight: '116px' }}>
    {
      isFetching.value ? (
        <Box sx={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
          <CircularProgress />
        </Box>
      ) : blocksContent.list.map((block) => <Block key={block.label} {...block} />)
    }
  </Box>;
})