import { IApplicationRouterExtension } from '../../../../application/ApplicationRouterExtensions.ts';
import { CTFMainPage } from '../../view/user/page/CTFMainPage.tsx';
import { CTFRoutes } from './CTFRoutes.ts';

export const CTFRouter: IApplicationRouterExtension[] = [
  {
    path: CTFRoutes.ctfAdminPage,
    pageTitle: 'CTF',
    component: CTFMainPage,
    allowedPermissions: [],
    fullHeight: true,
  },
  {
    path: CTFRoutes.ctfAdminStatisticPage,
    pageTitle: 'CTF',
    component: CTFMainPage,
    allowedPermissions: [],
    fullHeight: true,
  },
]
