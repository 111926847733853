import SearchIcon from '@mui/icons-material/Search';
import { Box, Typography } from "@mui/material";
import { observer } from 'mobx-react';
import React from 'react';
import { CTFInput } from './components/CTFInput';
import { CTFRatingTable } from "./components/CTFRatingTable";
import { Pagination } from './components/Pagination';
import { IRatingDomain } from './store/IRatingDomain';
import { RatingUI } from './store/RatingUI';

type ICTFRatingBlockProps = {
  raingDomain: IRatingDomain;
}

export const CTFRatingBlock = observer(({ raingDomain }: ICTFRatingBlockProps) => {
  const [{ boot, getCurrentPage, getRatingData, getTotalPage, onChangeSearch, handleChangePage }] = React.useState(new RatingUI(raingDomain));

  React.useEffect(() => {
    boot()
  }, []);

  const totalPage = getTotalPage();

  return (
    <Box sx={{
      display: 'flex',
      gap: '8px',
      flexDirection: 'column',
    }}>
      <Typography variant="h5">
        Рейтинг пользователей
      </Typography>
      <Box sx={{
        display: 'flex',
        gap: '16px',
        flexDirection: 'column',
        padding: '24px',
        background: 'rgba(255,255,255, .02)',
        borderRadius: '8px'
      }}>
        <CTFInput startAdornment={<SearchIcon color='disabled' />} onChange={onChangeSearch} />
        <CTFRatingTable items={getRatingData()} />
        {totalPage >= 2 ? <Pagination totalPage={totalPage} currentPage={getCurrentPage()} onChangePage={handleChangePage} /> : null}
      </Box>
    </Box>
  )
})