import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Grid, Tab, Theme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { TranslateText } from '../../../application/language/TranslateText';
import { AnalyticsPageHeader } from '../../../view/admin/page/analytics/parts/AnalyticsPageHeader';
import { AnalyticsDomain } from '../../../view/admin/page/analytics/store/AnalyticsDomain';
import { Colors } from '../../../view/design/color/Colors';
import { StudyDashboardWrapper } from '../../learning/view/analytics/components/StudyDashboardWrapper';

export const ASMAnalyticsPage = observer(({ layoutDomain }) => {
  const [analyticsDomain] = useState(new AnalyticsDomain(layoutDomain));
  const location = useLocation();
  const history = useHistory();

  const routes = {
    learning: '/analytics/learning',
  };

  const [tabValue, setTabValue] = useState(routes.learning);
  const classes = useStyles();

  useEffect(() => {
    analyticsDomain.loadReqData();
    setTabValue(location.pathname);
  }, [location, analyticsDomain]);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    history.push(newValue);
  };

  return (
    <Grid container display={'flex'} flexDirection={'column'} width={'100%'}>
      <AnalyticsPageHeader domain={analyticsDomain} />
      <TabContext value={tabValue}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <TabList onChange={handleTabChange} aria-label="analytics tabs" classes={{ indicator: classes.indicator }}>
            <Tab label={<TranslateText i18nKey={'phrases.learning'} />} value={routes.learning} />
          </TabList>
        </Box>

        <TabPanel value={routes.learning}>
          <StudyDashboardWrapper />
        </TabPanel>
      </TabContext>
    </Grid>
  );
});

const useStyles = makeStyles((theme: Theme) => ({
  indicator: {
    backgroundColor: Colors.selectedTabs('1'),
  },
}));
