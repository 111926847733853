import { observer } from 'mobx-react';
import React from 'react';

import { IMainLayoutDomainStore } from '../../../view/layout/main/store/domain/IMainLayoutDomainStore';
import { LearningAdminPage } from '../../learning/LearningAdminPage';
import { useLoadRedirectMainPageByRole } from '../../learning/router/useLoadRedirectMainPageByRole';

export interface IASMLearningPageProps {
  layoutDomain: IMainLayoutDomainStore;
}

export const ASMLearningPage = observer(({ layoutDomain }: IASMLearningPageProps) => {
  useLoadRedirectMainPageByRole({ layoutDomain });

  return <LearningAdminPage layoutDomain={layoutDomain} />;
});
