import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import { makeStyles } from '@mui/styles';
import { observer } from 'mobx-react';
import React from 'react';

import { IUnitModel } from '../../../../../service/unit/interface/IUnitModel';
import { OverflownContent } from '../../LearningUnitPreviewPage/shared/OverflownContent';
import { IUnitListAdminPageDomain } from '../store/IUnitListAdminPageDomain';

interface Props {
  title: string;
  units: IUnitModel[];
  domain: IUnitListAdminPageDomain;
}

export const UnitListCard = observer(({ title, units, domain }: Props) => {
  const classes = useStyles();

  return (
    <Accordion
      defaultExpanded={false}
      sx={{
        background: 'rgba(255, 255, 255, 0.04)',
        '&.Mui-expanded': { margin: '0' },
        '&.MuiAccordion-rounded': { borderRadius: '8px', boxShadow: 'none' },
        '&.MuiAccordion-rounded::before': { display: 'none' },
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{
          '&.Mui-expanded': { borderBottom: '0.5px solid rgba(255, 255, 255, 0.3)', minHeight: 'auto' },
          '&.MuiAccordionSummary-content': { justifyContent: 'space-between', alignItems: 'center' },
        }}
      >
        <Box
          sx={{
            background: 'rgba(255, 255, 255, 0.1)',
            borderRadius: '32px',
            alignSelf: 'self-start',
            padding: '0px 12px',
            marginRight: '8px',
          }}
        >
          {units.length}
        </Box>
        <OverflownContent contentClasses={classes.groupTitle}>{title}</OverflownContent>
      </AccordionSummary>
      <AccordionDetails>
        <Box
          sx={{
            gap: '8px',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {units?.map((unit) => (
            <OverflownContent
              key={unit.id}
              sx={{ '&:hover': { color: 'rgb(205, 205, 205)' } }}
              contentClasses={classes.groupTitle}
              onClick={() => {
                document.getElementById(unit.id)?.scrollIntoView({ behavior: 'smooth' });
              }}
            >
              {unit.settings.titleHeading}
            </OverflownContent>
          ))}
        </Box>
      </AccordionDetails>
    </Accordion>
  );
});

const useStyles = makeStyles(() => ({
  groupTitle: {
    fontWeight: '600',
    fontSize: '14px',
    cursor: 'pointer',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    whiteSpace: 'normal',
    lineClamp: 1,
  },
}));
