import { EnvNotExistsError } from "./EnvNotExistsError";

type IReadFrontEnvResult<T> = T | string;

type IReadFrontEnv = <ConverType = string, EnvsNames extends string = string>(arg:{
  name: EnvsNames,
  convertToClb?: (value: string) => ConverType;
  isRequired?: boolean;
  defaultValue?: IReadFrontEnvResult<ConverType>[];
  prefix?: string
}) => IReadFrontEnvResult<ConverType>[] | never;


export const readFrontEnv: IReadFrontEnv = ({ name, convertToClb, defaultValue, isRequired, prefix = '' }) => {
  const envInNode = process.env[`${prefix}${name}`];
  if (!envInNode && defaultValue) return defaultValue;

  if (isRequired && !envInNode) throw new EnvNotExistsError({ name, place: 'FRONTEND'});

  return (envInNode || '')?.split(',').map(item => convertToClb ? convertToClb(item.trim()): item.trim())
}

