import { Box, CircularProgress, List, ListItem, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';
import { UsersRangeResultsDomain } from './store/UsersRangeResultsDomain';
import { UsersResultsRangeUI } from './store/UsersResultsRangeUI';

import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  XAxis,
  YAxis
} from 'recharts';
import { Colors } from '../../../../../../../view/design/color/Colors';
import { BlockPapper } from '../../../../design/papper/BlockPapper';

function formatNumberWithSpace(value: any): string {
  const strValue: string = value.toString();

  if (strValue.length < 5) {
    return strValue;
  }

  return strValue.replace(/\B(?=(\d{3})+(?!\d))/g, "\u202F");
}

const TopTick = (props) => {
  const { payload, x, y } = props

  const label = useMemo(() => formatNumberWithSpace(payload.value), [payload.value])

  return (
    <text x={x} y={y} textAnchor="middle" orientation='top' fill="#666">
      <tspan>
        {label}
      </tspan>
    </text>

  );
}

const LegendBlock = observer((props) => {
  const [{ groupsMeta }] = React.useState(() => new UsersResultsRangeUI(new UsersRangeResultsDomain()));

  const { payload } = props;

  return <Box>
    <List disablePadding sx={{
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '16px',
    }} >
      {
        payload.map((entry, index) => (
          <ListItem
            sx={{ display: 'inline-flex', width: 'auto', color: groupsMeta[entry.value].color, gap: '8px', }}
            disablePadding
            key={`item-${index}`}><Box sx={{
              width: '12px',
              height: '12px',
              borderRadius: '2px',
              background: groupsMeta[entry.value].color,
            }} />
            <Typography>
              {entry.value}
            </Typography> </ListItem>
        ))
      }
    </List>
  </Box>
})

const THOUSAND = 1000;
const MILLION = THOUSAND * THOUSAND;
const BILLIARD = THOUSAND * MILLION;

const formatNumberToKilos = (value: string | number) => {
  const realNumber = typeof value === 'string' ? parseFloat(value) : value;

  if (realNumber >= BILLIARD) {
    return (realNumber / BILLIARD).toString() + 'KKK';
  } else if (realNumber >= MILLION) {
    return (realNumber / MILLION).toString() + 'KK';
  } else if (realNumber >= THOUSAND) {
    return (realNumber / THOUSAND).toString() + 'K';
  } else {
    return realNumber.toString();
  }
}

const BACKGROUND_FOR_BAR = { fill: '#434E54', fillOpacity: '0.40', stroke: '#434E54', strokeWidth: 1 };

export const UsersResultsGraph = observer(() => {
  const [{ boot, dataRanges, isFetching, groupsMeta }] = React.useState(() => new UsersResultsRangeUI(new UsersRangeResultsDomain()));

  const bars = React.useMemo(() => Object.keys(groupsMeta).map((key) => ({
    ...groupsMeta[key],
    label: key,
  })), [groupsMeta]);

  React.useEffect(() => {
    boot();
  }, []);

  return <Box height={340}>
    <BlockPapper
      sx={{ gap: '16px', flexDirection: 'column' }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        height: 40
      }}>
        <Typography variant='h6' >Распределение результатов</Typography>
      </Box>

      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
      }}>
        {isFetching.value ? (
          <CircularProgress />
        ) :
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={dataRanges.list}>
              <XAxis dataKey="name" />
              <YAxis tickFormatter={formatNumberToKilos} >
                <Label angle={-90} fontSize={12} fill='#fff' opacity={.7} position={'insideBottomLeft'}>
                  Количество пользователей
                </Label>
              </YAxis>
              <XAxis xAxisId="3" orientation='top' dataKey="total" tick={<TopTick />} opacity={.0} />
              {bars.map(({ dataKey, color, label }, index) => (
                <Bar key={dataKey} stackId="a" dataKey={dataKey} fill={color} fillOpacity={.8} name={label} stroke={color} strokeWidth={1} background={index === 0 ? BACKGROUND_FOR_BAR : undefined} />
              ))}
              <CartesianGrid strokeDasharray="3 3" stroke={Colors.blackOpacity('.1')} vertical={false} fillOpacity={0.3} />

              <Legend content={<LegendBlock />} />
            </BarChart>
          </ResponsiveContainer>
        }
      </Box>
    </BlockPapper>
  </Box>
})
