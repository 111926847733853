import { commonContainer } from '../../common/commonContainer';
import { IContainer } from '../../common/container/IContainer';
import { serviceContainer } from '../../service/serviceContainer';
import { layoutContainer } from '../../view/layout/layoutContainer';
import { leftNavigationContainer } from '../../view/layout/main/parts/LeftNavigation/container/leftNavigationContainer';
import { userPageContainer } from '../../view/user/page/userPageContainer';
import { languageContainer } from '../language/languageContainer';
import { routerContainer } from '../routerContainer';

export async function setDefaultRootContainer(container: IContainer): Promise<IContainer> {
  container = serviceContainer(container);
  container = commonContainer(container);
  container = await languageContainer(container);
  container = layoutContainer(container);
  container = userPageContainer(container);
  container = routerContainer(container);
  container = leftNavigationContainer(container);

  return container;
}
