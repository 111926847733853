import React from 'react';
import {
  Bar,
  BarChart,
  Brush,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

import { useI18n } from '../../../../../../application/language/useI18n';
import styles from './Dashboard.module.css';
import { IDashboardDataItem } from './interface/IDashboardDataItem';

const LEGEND = [
  { name: 'analytics.notStarted', color: '#757575', code: 'dv' },
  { name: 'analytics.inProgress', color: '#FFCA28', code: 'uv' },
  { name: 'analytics.completed', color: '#4CAF50', code: 'pv' },
];

const COLOR_TO_CODE_MAP = {
  dv: { name: 'analytics.notStarted', color: '#757575', code: 'dv' },
  uv: { name: 'analytics.inProgress', color: '#FFCA28', code: 'uv' },
  pv: { name: 'analytics.completed', color: '#4CAF50', code: 'pv' },
};

const CustomTooltip = (props) => {
  const { translate } = useI18n()
  return (
    <div className={styles.customTooltip}>
      <div className={styles.customTooltip__label}>{props.label}</div>
      <div className={styles.customTooltip__itemsContainer}>
        {props?.payload?.map((item, i) => {
          return (
            <div className={styles.customTooltip__itemsContainer__item}>
              <div className={styles.customTooltip__itemsContainer__itemContainer}>
                <svg width="12" height="12">
                  <rect rx="2" ry="2" width="12" height="12" fill={COLOR_TO_CODE_MAP[item.dataKey].color} />
                </svg>
                <div>{translate(COLOR_TO_CODE_MAP[item.dataKey].name)}</div>
              </div>
              <div>
                {item.payload[item.dataKey]}%{' '}
                <span className={styles.customTooltip__itemsContainer__itemPercentage}>
                  ({item.payload[`${item.dataKey}Count`]} {translate('analytics.employees')?.toLowerCase()})
                </span>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const CustomLegend = () => {
  const { translateAnyway } = useI18n();

  return (
    <div className={styles.customLegend}>
      {LEGEND.map((item, index) => (
        <div key={index} className={styles.customLegend__item}>
          <svg width="16" height="16">
            <rect rx="2" ry="2" width="16" height="16" fill={item.color} />
          </svg>
          <span className={styles.customLegend__itemName} style={{ color: item.color }}>
            {translateAnyway(item.name)}
          </span>
        </div>
      ))}
    </div>
  );
};

const CustomBarLabel = ({ x, y, width, value, data, name }) => {
  const currentBar = data.find((item) => item.name === name);
  const usersCount = currentBar.dvCount + currentBar.uvCount + currentBar.pvCount;
  return (
    <g>
      <text x={x + width / 2} y={y - 10} fill="#BDBDBD" textAnchor="middle" dominantBaseline="middle">
        {usersCount}
      </text>
    </g>
  );
};

const tickFormatter = (value, data) => {
  if (value.length > 100 && data.length < 2) {
    return `${value.slice(0, 97).trim()}...`;
  } else if (value.length > 50 && data.length === 2) {
    return `${value.slice(0, 47).trim()}...`;
  } else if (value.length > 25 && data.length > 2 && data.length < 5) {
    return `${value.slice(0, 22).trim()}...`;
  } else if (value.length > 15 && data.length >= 5 && data.length < 8) {
    return `${value.slice(0, 12).trim()}...`;
  } else if (value.length > 10 && data.length >= 8) {
    return `${value.slice(0, 7).trim()}...`;
  }
  return value;
};

const TICKS = Array.from({ length: 11 }, (_, i) => i * 10);

type IProps = {
  data: IDashboardDataItem[]
}
export const Dashboard = ({ data }: IProps) => {
  const { translate } = useI18n()

  return (
    <div className={styles.dashboardContainer} style={{ width: '100%', height: 'calc(100vh - 340px)' }}>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          height={300}
          data={data}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" stroke="rgba(255, 255, 255, 0.5)" vertical={false} opacity={.3} />
          <XAxis dataKey="name" tickFormatter={(value) => tickFormatter(value, data)} />
          <YAxis
            label={{
              value: `${translate('phrases.users')}, %`,
              angle: -90,
              position: 'insideLeft',

              style: {
                textAnchor: 'middle',
                fill: '#666',
              },
            }}
            ticks={TICKS}
          />
          <Tooltip cursor={{ fill: '#434E54', opacity: 0.1 }} content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
          {data.length > 10 && (
            <Brush dataKey="name" height={30} startIndex={0} endIndex={10} fill="none" stroke="#BDBDBD" travellerWidth={10} />
          )}
          <Bar dataKey="pv" stackId="a" fill="#4CAF50" opacity={0.7}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} stroke="#69cf6c" strokeWidth={2} />
            ))}
          </Bar>
          <Bar dataKey="uv" stackId="a" fill="#FFCA28" opacity={0.7}>
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} stroke="#ffd967" strokeWidth={1} />
            ))}
          </Bar>
          <Bar
            dataKey="dv"
            stackId="a"
            fill="#434E54"
            opacity={.0}
            strokeOpacity={0}
            label={(props) => {
              return <CustomBarLabel name={props.name} data={data} {...props} />;
            }}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} stroke="#758993" strokeWidth={1} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};
