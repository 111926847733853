import { injectPrimitive } from "../../../../../../../common/store/base/injectPrimitive";
import { IPrimitiveStore } from "../../../../../../../common/store/interface/IPrimitiveStore";
import { saveFileInBrowser } from "../../../../../../../common/utils/saveFileInBrowser";
import { IFullStudyReportDomain } from "./IFullStudyReportDomain";
import { IStudyReportNavbarUI } from "./IStudyReportNavbarUI";

const REPORT_FILE_TYPE = 'csv';

const createReportFileName = (type: string) => `report.${type}`;

export class StudyReportNavbarUI implements IStudyReportNavbarUI {
  isFetching: IPrimitiveStore<boolean>;

  constructor(private fullStudyReportDomain: IFullStudyReportDomain) {
    this.isFetching = injectPrimitive(false);
  }

  handleDownload = async () => {
    if (this.isFetching.value) return;
    this.isFetching.setValue(true);

    return this.fullStudyReportDomain.downloadReport({ type: REPORT_FILE_TYPE }).then(blob => saveFileInBrowser(createReportFileName(REPORT_FILE_TYPE), blob)).finally(() => this.isFetching.setValue(false));
  }
}