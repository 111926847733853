import { Divider } from "@mui/material";
import React, { Fragment, useContext, useMemo } from "react";
import { ContainerContext } from '../../../../../common/container/ContainerContext';
import { IMainLayoutDomainStore } from "../../store/domain/IMainLayoutDomainStore";
import { AdditionalNavItemToken } from './container/AdditionalNavItem/AdditionalNavItemToken';
import { IAdditionalNavItem } from './container/AdditionalNavItem/IAdditionalNavItem';
import { useStyles } from './useStyles';

type Props = {
  layoutDomain: IMainLayoutDomainStore;
}
export const AdditionalNavItems = ({ layoutDomain }: Props) => {
  const context = useContext(ContainerContext);
  const classes = useStyles();

  const additioanlItems: IAdditionalNavItem[] = useMemo(() => context.getByToken(AdditionalNavItemToken), [context]);

  if (additioanlItems.length === 0) return null;


  return (
    <Fragment>
      <Divider />
      <div className={classes.navContainer}>
        {
          additioanlItems.map(({ key, Component }) => (
            <Component key={key} layoutDomain={layoutDomain} />
          ))
        }
      </div>
    </Fragment>
  )
}