import React from 'react';

import { InputBase, InputBaseProps } from "@mui/material";
import { makeStyles } from '@mui/styles';
import { debounce } from 'lodash';

const useStyles = makeStyles(() => ({
  inputClass: {
    display: 'flex',
    gap: '10px',
    flexDirection: 'row',
    padding: '10px',
    alignItems: 'center',
    background: 'rgba(255,255,255, .03)',
    borderRadius: '8px'
  }
}));

type ICTFInput = Pick<InputBaseProps, 'startAdornment' | 'onChange' | 'value'>;

export const CTFInput = React.memo(({ onChange, ...restInputBaseProps }: ICTFInput) => {
  const { inputClass } = useStyles()
  const ownOnChange = React.useCallback((args) => onChange ? debounce(onChange, 300)(args) : null, [onChange]);

  return <InputBase
    placeholder="Поиск других участников"
    className={inputClass}
    onChange={ownOnChange}
    {...restInputBaseProps} />
})