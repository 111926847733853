import { Chip } from '@mui/material';
import React from 'react';
import { ListItemLink } from '../../../../../view/design/list/ListItemLink';
import { IAdditionalNavItemComponentProps } from '../../../../../view/layout/main/parts/LeftNavigation/container/AdditionalNavItem/IAdditionalNavItem';
import { CTFRoutes } from '../../../service/router/CTFRoutes';

export const CTFNavItemLink = ({ layoutDomain }: IAdditionalNavItemComponentProps) => {

  return <ListItemLink
    itemKey='ctf'
    url={CTFRoutes.ctfAdminPage}
    primaryText='CTF'
    after={<Chip label="New" color="secondary" />}

  />
}