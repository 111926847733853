import { colors } from "@mui/material";
import { injectEntityList } from "../../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../../common/store/base/injectPrimitive";
import { IEntityListStore } from "../../../../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../../../../common/store/interface/IPrimitiveStore";
import { IChallengeByCategory } from "../../../../../../service/statistc/interface/IChallengeByCategory";
import { IPieColvedCountRange } from "../../../../../design/chart/PieCTFChart";
import { IChallengesByGroupDomain } from "./IChallengesByGroupDomain";
import { ChallengesGroupsName, IChallengesByGroupUI } from "./IChallengesByGroupUI";

const COLORS_FOR_PIE = [
  colors.orange[500],
  colors.pink[500],
  colors.purple[500],
  colors.indigo[500],
  colors.lime[500],
  colors.blue[500],
];

export class ChallengesByGroupUI implements IChallengesByGroupUI {
  private alreadyBooted: IPrimitiveStore<boolean>;
  isFetching: IPrimitiveStore<boolean>;
  data: IEntityListStore<IPieColvedCountRange<ChallengesGroupsName>>;
  domain: IChallengesByGroupDomain;

  constructor(
    domain: IChallengesByGroupDomain
  ) {
    this.domain = domain;
    this.alreadyBooted = injectPrimitive(false);
    this.isFetching = injectPrimitive(false);
    this.data = injectEntityList<IPieColvedCountRange<ChallengesGroupsName>>([]);
  }

  loadData = async () =>  {
    if (this.alreadyBooted.value) return;
    this.isFetching.setValue(true);

    try {
      const newData = await this.domain.getData();
      this.data.setList(this.APItoRangeDTO(newData));
      this.alreadyBooted.setValue(true);
    } finally {
      this.isFetching.setValue(false);
    }
  }

  private APItoRangeDTO = (data: IChallengeByCategory[]): IPieColvedCountRange<ChallengesGroupsName>[] => {
    return data.sort((a,b) => a.category > b.category ? -1 : 1).map(({ category, solves }, index) => ({
      name: category,
      value: solves,
      color: COLORS_FOR_PIE[index % COLORS_FOR_PIE.length],
      label: `${category} (${solves})`,
    }))
  }
}