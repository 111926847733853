import { AccessTime } from '@mui/icons-material';
import { makeStyles } from '@mui/styles';
import classNames from 'classnames';
import { useCallback, useContext } from 'react';
import React from 'react';

import { useI18n } from '../../../../../../../../application/language/useI18n';
import { PreviewAccordion } from '../../shared/PreviewAccordion';
import { LearningUnitPreviewPageContext } from '../../store/LearningUnitPreviewPageContext';
import { PreviewSidebarList } from '../LearningUnitPreviewSidebar/components/PreviewSidebarList';
import { PreviewSidebarTheory } from '../LearningUnitPreviewSidebar/components/PreviewSidebarTheory';

const isDisabled = (step, activeSteps) => !activeSteps.find((item) => item === step);

export const SidebarStepsComponents = ({ unit }) => {
  const { translate } = useI18n();
  const classes = useStyles();
  const { unitPreviewDomain } = useContext(LearningUnitPreviewPageContext);
  const currentStep = unitPreviewDomain?.ui?.currentStep.value;
  const activeSteps = unitPreviewDomain?.ui?.activeSteps.list;
  const setCurrentStep = unitPreviewDomain?.ui?.currentStep.setValue;
  const setCurrentSubtep = unitPreviewDomain?.ui?.currentSubstep.setValue;

  const quizItems = unit?.quiz?.questions?.map((question, index) => ({
    title: `${translate('phrases.question')} ${index + 1}`,
    description: question?.name,
    value: String(index),
  }));

  const practiceItems = unit?.practice?.tasks?.map((task, index) => ({
    title: task?.name,
    description: task?.explanation,
    value: String(index),
  }));

  const getTitleClasses = useCallback(
    (title) => classNames(title === currentStep ? classes.titleActive : ''),
    [classes.titleActive, currentStep],
  );

  return (
    <>
      <PreviewAccordion
        disabled={isDisabled('theory', activeSteps)}
        titleClasses={getTitleClasses('theory')}
        onTitleClick={() => {
          setCurrentStep?.('theory');
          setCurrentSubtep?.(unit?.theory?.chapters[0]?.title);
        }}
        title={translate('phrases.theory')}
        additionalInfo={
          isDisabled('theory', activeSteps) ? (
            <div className={classes.accordionAdditionalInfo}>{translate(`phrases.notAdded`)}</div>
          ) : (
            <div className={classes.accordionAdditionalInfo}>
              <AccessTime />
              <span>30 {translate('phrases.min')}</span>
            </div>
          )
        }
      >
        <PreviewSidebarTheory
          onItemClick={(item) => {
            setCurrentStep?.('theory');
            setCurrentSubtep?.(item);
          }}
          theory={unit?.theory}
        />
      </PreviewAccordion>
      <PreviewAccordion
        disabled={isDisabled('quiz', activeSteps)}
        titleClasses={getTitleClasses('quiz')}
        onTitleClick={() => {
          setCurrentStep?.('quiz');
          setCurrentSubtep?.(0);
        }}
        title={translate('phrases.quiz')}
        additionalInfo={
          isDisabled('quiz', activeSteps) ? (
            <div className={classes.accordionAdditionalInfo}>{translate(`phrases.notAdded`)}</div>
          ) : (
            <div className={classes.accordionAdditionalInfo}>
              <AccessTime />
              <span>{unit?.quiz?.passageTime} {translate('phrases.min')}</span>
            </div>
          )
        }
      >
        <PreviewSidebarList
          onItemClick={(item) => {
            setCurrentStep?.('quiz');
            setCurrentSubtep?.(item.value);
          }}
          items={quizItems}
        />
      </PreviewAccordion>
      <PreviewAccordion
        disabled={isDisabled('practice', activeSteps)}
        titleClasses={getTitleClasses('practice')}
        onTitleClick={() => {
          setCurrentStep?.('practice');
          setCurrentSubtep?.(0);
        }}
        title={translate(`phrases.practice`)}
        additionalInfo={
          isDisabled('practice', activeSteps) ? (
            <div className={classes.accordionAdditionalInfo}>{translate(`phrases.notAdded`)}</div>
          ) : (
            <div className={classes.accordionAdditionalInfo}>
              <AccessTime />
              <span>{unit?.practice?.passageTime} {translate('phrases.min')}</span>
            </div>
          )
        }
      >
        <PreviewSidebarList
          onItemClick={(item) => {
            setCurrentStep?.('practice');
            setCurrentSubtep?.(item.value);
          }}
          items={practiceItems}
        />
      </PreviewAccordion>

      <PreviewAccordion
        isExpandable={false}
        titleClasses={getTitleClasses('finalResults')}
        onTitleClick={() => {
          setCurrentStep?.('finalResults');
          setCurrentSubtep?.(0);
        }}
        title={translate(`phrases.results`)}
      ></PreviewAccordion>
    </>
  );
};

export const useStyles = makeStyles(() => ({
  accordionAdditionalInfo: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    gap: '4px',
  },
  titleActive: {
    color: '#90CAF9',
  },
}));
