import { injectEntityList } from "../../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../../common/store/base/injectPrimitive";
import { IEntityListStore } from "../../../../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../../../../common/store/interface/IPrimitiveStore";
import { Colors } from "../../../../../../../../view/design/color/Colors";
import { ICorrectIncorrectCount } from "../../../../../../service/statistc/interface/ICorrectIncorrectCount";
import { IPieColvedCountRange } from "../../../../../design/chart/PieCTFChart";
import { ICountsSolvedDomain } from "./ICountsSolvedDomain";
import { ICountsSolvedUI, SolvedChallengesGroupsName } from "./ICountsSolvedUI";

export class CountsSolvedUI implements ICountsSolvedUI {
  private alreadyBooted: IPrimitiveStore<boolean>;
  isFetching: IPrimitiveStore<boolean>;
  data: IEntityListStore<IPieColvedCountRange<SolvedChallengesGroupsName>>;
  domain: ICountsSolvedDomain;

  constructor(
    domain: ICountsSolvedDomain
  ) {
    this.domain = domain;
    this.alreadyBooted = injectPrimitive(false);
    this.isFetching = injectPrimitive(false);
    this.data = injectEntityList<IPieColvedCountRange<SolvedChallengesGroupsName>>([]);
  }

  loadData = async () =>  {
    if (this.alreadyBooted.value) return;
    this.isFetching.setValue(true);

    try {
      const newData = await this.domain.getData();
      this.data.setList(this.APItoRangeDTO(newData));
      this.alreadyBooted.setValue(true);
    } finally {
      this.isFetching.setValue(false);
    }
  }

  private APItoRangeDTO = ({ correct = 0, incorrect = 0 }: ICorrectIncorrectCount): IPieColvedCountRange<SolvedChallengesGroupsName>[] => {
    return [{
      name: 'correct',
      value: correct,
      color: Colors.positiveActive,
      label: `Успешно пройденные задания (${correct})`
    }, {
      name: 'incorrect',
      value: incorrect,
      color: Colors.negativeActive,
      label: `Провал заданий (${incorrect})`
    }]
  }
}