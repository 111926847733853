import { UserSystemRoleModelPermissionMap } from '../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { ApplicationTemplateEdit } from '../../../../user/page/application/info/ApplicationTemplateEdit';
import { ApplicationTemplateAdminTable } from '../../application/template/ApplicationTemplateAdminTable';
import { ISettingsItem } from '../ISettingsItem';

export const ApplicationTemplateSettingsItem: ISettingsItem = {
  id: 'applicationTemplate',
  settingsItem: {
    key: 'applicationTemplate',
    title: 'settings.templatesTitle',
    buttons: [
      {
        title: 'settings.templatesTitle',
        url: `/settings/application/template`,
        permissions: [UserSystemRoleModelPermissionMap['settings-application-template-read']],
      },
    ],
    permissions: [UserSystemRoleModelPermissionMap['settings-application-template-read']],
    infoText: 'settings.templatesDes',
    orderIndex: 1000,
  },
  routers: [
    {
      key: 'application-template-list-settings',
      path: '/settings/application/template',
      pageTitle: 'phrases.templates',
      component: ApplicationTemplateAdminTable,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-application-template-read']],
    },
    {
      key: 'application-template-edit-settings',
      path: '/settings/application/template/:applicationId/edit',
      pageTitle: 'phrases.editTemplate',
      component: ApplicationTemplateEdit,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-application-template-update']],
    },
    {
      key: 'application-template-new-settings',
      path: '/settings/application/template/new',
      pageTitle: 'phrases.addTemplate',
      component: ApplicationTemplateEdit,
      allowedPermissions: [UserSystemRoleModelPermissionMap['settings-application-template-create']],
    },
  ],
};
