import { CircularProgress, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';

import { TranslateText } from '../../../../../../application/language/TranslateText';
import { useI18n } from '../../../../../../application/language/useI18n';
import { Dashboard } from '../../shared/dashboards/Dashboard';
import styles from './index.module.css';
import { DashboardDomain } from './store/DashboardDomain';
import { StudyResultsDashboardUI } from './store/StudyResultsDashboardUI';

export const StudyResultsDashboard = observer(() => {
  const [{ isFetching, boot, dashboardData }] = useState(() => new StudyResultsDashboardUI(new DashboardDomain()))
  const { translate } = useI18n();

  useEffect(() => {
    boot();
  }, []);

  if (isFetching.value) {
    return (
      <div className={styles.spinnerContainer}>
        <CircularProgress />
      </div>
    );
  }


  if (!dashboardData.list.length) {
    return (
      <div className={styles.noResultsContainer}>
        <img src="/pictures/UnitsDashboardNoresults.png" />
        <Typography typography="h5">
          <TranslateText i18nKey={'analytics.noResults'} />
        </Typography>
        <Typography typography="subtitle2" sx={{ opacity: 0.7 }}>
          <TranslateText i18nKey={'analytics.noResultsMessage'} />
        </Typography>
      </div>
    );
  }

  return (
    <div
      style={{
        background: '#2b353a',
        borderRadius: '10px',
        padding: '24px',
      }}
    >
      <div
        style={{
          fontWeight: 500,
          fontSize: '20px',
          marginBottom: '20px',
        }}
      >
        {`${translate('analytics.resultsByProject')}${dashboardData.list.length ? ` (${dashboardData.list.length})` : ''}`}
      </div>
      <Dashboard data={dashboardData.list} />
    </div>
  );
});
