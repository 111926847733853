import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import { Box, Icon, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { Colors } from '../../../../../../learning/view/design/colors/Colors';
import { Img } from '../../../../design/img/Img';
import { CTFVideoBlockUI } from './store/CTFVideoBlockUI';

const useStyles = makeStyles(() => ({
  img: {
    objectFit: 'cover',
    width: "100%",
  },
}));


export const CTFVideoBlock = memo(() => {
  const [{ videoLink }] = React.useState(new CTFVideoBlockUI());
  const styles = useStyles();

  if (!videoLink) return null;


  return <Box sx={{
    display: 'flex',
    gap: '24px',
    border: '1px solid rgba(255, 255, 255, 0.18)',
    borderRadius: '8px',
    padding: '16px 24px'
  }}>


    <Link href={videoLink} target="_blank" rel="noopener noreferrer"
      sx={{
        borderRadius: '8px',
        maxWidth: 334,
        minWidth: 334,
        overflow: 'hidden',
        display: 'flex',
        position: 'relative'
      }}>
      <Img src="/extension/ctf/videoPreview.jpg" className={styles.img} />
      <Box sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
        <Icon
          color="secondary"
          sx={{
            background: Colors.newestActiveOpacity('1'),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: 44,
            height: 36,
            borderRadius: 2
          }}>
          <PlayArrowOutlinedIcon sx={{
            width: 20,
            height: 20
          }} />
        </Icon>
      </Box>
    </Link>



    <Box sx={{
      display: 'flex',
      gap: '8px',
      flexDirection: 'column'
    }}>
      <Typography variant='h5'>
        Введение в приложение CTF
      </Typography>
      <Typography variant='body1'>
        Тренажер по практической безопасности в формате интернет-банка.
        Позволит за 4 недели познакомить команды с темой безопасной разработки и получить позитивный эффект вовлеченности сотрудников: разработчиков, инженеров и других членов продуктовых команд.
      </Typography>
    </Box>
  </Box>
})