import { IRestRatingService } from "../../../../../../service/rating/IRestRatingService";
import { RestRatingService } from "../../../../../../service/rating/RestRatingService";
import { IUsersRangeResultsDomain } from "./IUsersRangeResultsDomain";

export class UsersRangeResultsDomain implements IUsersRangeResultsDomain {
  restRatingService: IRestRatingService
  constructor() {
    this.restRatingService = new RestRatingService()
  }

  loadRatingWithRange = () => this.restRatingService.getUsersScoreByGroup();
}