import { ConfigManager } from '../../../../../../application/config/ConfigManager';
import { IConfig } from '../../../../../../application/config/IConfig';
import { ConstructorType, inject, injectValue } from '../../../../../../common/container/inject';
import { IError } from '../../../../../../common/error/IError';
import { Store } from '../../../../../../common/store/base/Store';
import { IProjectModel } from '../../../../../../service/project/IProjectModel';
import { ProjectState } from '../../../../../../service/project/ProjectState';
import { UserProjectRoleAction } from '../../../../../../service/projectRole/entity/actions/UserProjectRoleAction';
import { injectRootService } from '../../../../../../service/RootServiceFactory';
import { IRouterService, RouterServiceToken } from '../../../../../../service/route/IRouterService';
import { UserSystemRoleModelPermissionMap } from '../../../../../../service/systemRole/entity/actions/UserSystemRoleModelPermissionMap';
import { IMainLayoutDomainStore } from '../../../../../layout/main/store/domain/IMainLayoutDomainStore';
import { UserProjectItemStatus, UserProjectItemView } from './model/UserProjectItemView';
import { ProjectListPageUi } from './ProjectListPageUi';
import { IContainer } from '../../../../../../common/container/IContainer';
import { injectPrimitive } from '../../../../../../common/store/base/injectPrimitive';
import { IProjectSearchFilter } from '../../../../../../service/project/IProjectSearch';

export class ProjectListDomain extends Store {
  constructor(
    public layoutDomain: IMainLayoutDomainStore,
    private container: IContainer,
    public ui = new (injectValue<ConstructorType<ProjectListPageUi>>(ProjectListPageUi))(),
    private router: IRouterService = inject<IRouterService>(RouterServiceToken),
    private rootService = injectRootService(layoutDomain.serviceType.value),
    public config: IConfig = ConfigManager.getConfig(),
    public projectFilters = injectPrimitive<IProjectSearchFilter>({}),
  ) {
    super();
  }

  async loadData(): Promise<void> {
    this.ui.startLoading();
    const isCanCreate = this.layoutDomain.userHaveAnyAccess([
      UserSystemRoleModelPermissionMap['project-not-in-team-permission-create-project'],
    ]);

    this.ui.isCanCreate.setValue(isCanCreate);
    await this.getCountOfProjectsAndApplications();

    await this.triggerSearch(0);

    if (
      this.ui.userProjects.list.length === this.ui.projectsTotalCount.value ||
      this.ui.projectsTotalCount.value <= 20
    ) {
      this.ui.isAllProjectsRendered.setValue(true);
    }
    const systemVendors = await this.rootService.vendor.entity.getSystemVendors();
    this.ui.setSystemVendors(systemVendors);

    this.ui.endLoading();
  }

  getIsCanEdit(projectId: string) {
    const project = this.ui.userProjects.list.find((project) => project.id === projectId);
    const editProjectPermission = this.layoutDomain.userHaveAnyAccessToEditProject(projectId)
    if (project?.isUnitedWithApplication) {

      const isCanEditProject = editProjectPermission.isCanEditFields || editProjectPermission.isCanEditTeam

      const isCanEditApplication = this.layoutDomain.userHaveAnyAccess(
        [UserSystemRoleModelPermissionMap['project-not-in-team-permission-update-application']],
        { id: projectId, permissions: [UserProjectRoleAction.editApplication] },
      );

      return isCanEditProject && isCanEditApplication;
    } else {
      const isCanEditProject = editProjectPermission.isCanEditFields || editProjectPermission.isCanEditTeam

      return isCanEditProject;
    }
  }

  getIsCanDelete(projectId: string) {
    const project = this.ui.userProjects.list.find((project) => project.id === projectId);
    if (project?.isUnitedWithApplication) {
      const isCanEditProject = this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-project'],
      ]);
      const isCanEditApplication = this.layoutDomain.userHaveAnyAccess(
        [UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-application']],
        { id: projectId, permissions: [UserProjectRoleAction.editApplication] },
      );

      return isCanEditProject && isCanEditApplication;
    } else {
      const isCanDeleteProject = this.layoutDomain.userHaveAnyAccess([
        UserSystemRoleModelPermissionMap['project-not-in-team-permission-delete-project'],
      ]);

      return isCanDeleteProject;
    }
  }

  onEditProject = (projectId: string) => {
    const project = this.ui.userProjects.list.find((project) => project.id === projectId);
    if (project?.isUnitedWithApplication) {
      this.router.goTo(`/project/edit/${projectId}/application/${project.unitedApplicationId}`);
    } else {
      this.router.goTo(`/project/${projectId}/edit`);
    }
  };

  onDeleteProject = async (projectId: string) => {
    const project = this.ui.userProjects.list.find((project) => project.id === projectId);
    if (project?.isUnitedWithApplication && project?.unitedApplicationId) {
      await this.rootService.application.entity.deleteById(project.unitedApplicationId || '');
      await this.rootService.project.entity.deleteById(projectId);
      await this.loadData();
    } else {
      await this.rootService.project.entity.deleteById(projectId);
      await this.loadData();
    }
  };

  async mapProjectInfoFromServiceToView(userProjectsInfo: IProjectModel[]): Promise<UserProjectItemView[]> {
    const mapStatus = (type?: ProjectState): UserProjectItemStatus => {
      const mapObject: any = {
        [ProjectState.down]: UserProjectItemStatus.down,
        [ProjectState.stable]: UserProjectItemStatus.stable,
        [ProjectState.up]: UserProjectItemStatus.grow,
      };
      return mapObject[type || UserProjectItemStatus.stable];
    };

    const defaultUserProjectsInfo = userProjectsInfo.map((projectModel): UserProjectItemView => {
      const defaultProjectInfo = {
        id: projectModel.id || '',
        title: projectModel.name || '',
        subtitle: projectModel.comment || '',
        status: mapStatus(projectModel.state),
        projectApplicationsCount: projectModel.applicationsCount || 0,
        projectRequirementCount: projectModel.requirementCount || 0,
        lastActivity: projectModel.lastActivity || null,
        isUnitedWithApplication: projectModel.isUnitedWithApplication || false,
        unitedApplicationId: projectModel.unitedApplicationId || null,
        rolesMap: projectModel.rolesMap || { data: [] },
      };

      return defaultProjectInfo;
    });

    return defaultUserProjectsInfo;
  }

  private dataLoadErrorCatch = (error: IError) => {
    this.layoutDomain.errors.handleError(error);
    return {
      data: [],
    };
  };

  async triggerSearch(offset: number = 0) {
    const filters = await this.addSearchProjectsFilters();

    this.ui.isLoadingProjects.setValue(true);
    this.ui.projectListOffset.setValue(offset);

    const userProjects = await this.rootService.project.entity
      .search({
        offset: this.ui.projectListOffset.value,
        limit: 20,
        filter: {
          ...filters,
        },
        fields: ['id', 'applicationsCount', 'lastActivity', 'requirementCount', 'state', 'name', 'isUnitedWithApplication', 'unitedApplicationId', 'codeName']
      })
      .catch(this.dataLoadErrorCatch);

    const viewProjectInfoModels = await this.mapProjectInfoFromServiceToView(userProjects.data);

    if (offset === 0) {
      this.ui.setUserProjects(viewProjectInfoModels);
    } else {
      this.ui.setUserProjects([...this.ui.userProjects.list, ...viewProjectInfoModels]);
    }

    if (this.ui.projectListOffset.value >= this.ui.projectsTotalCount.value || userProjects.data.length < 20) {
      this.ui.isAllProjectsRendered.setValue(true);
    } else {
      this.ui.isAllProjectsRendered.setValue(false);
    }

    this.ui.isLoadingProjects.setValue(false);
  }

  async addSearchProjectsFilters() {
    const filter = this.projectFilters.value;
    filter.name = {
      like: {
        value: this.ui.searchProjectTerm.value,
        caseInsensitive: false,
      },
    };

    return filter;
  }

  async getCountOfProjectsAndApplications() {
    const count = await this.rootService.project.entity.getCount().catch(this.dataLoadErrorCatch);
    this.ui.projectsTotalCount.setValue(count?.projectCount || 0);
    this.ui.applicationsTotalCount.setValue(count?.applicationCount || 0);
  }
}
