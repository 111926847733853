import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import React from 'react';

import { StudyReportNavbar } from './StudyReportNavbar';
import { StudyResultsDashboard } from './StudyResultsDashboard';

export const StudyDashboardWrapper = observer(() => {

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
      <StudyReportNavbar />
      <StudyResultsDashboard />
    </Box>
  );
});
