import { Box } from '@mui/material';
import React from 'react';

import { useI18n } from '../../../application/language/useI18n';
import { AppSecLogo } from '../../../view/design/icon/AppSecLogo';
import { EDUIcon } from '../../../view/design/icon/EDUIcon';
import { HumanFactorLogo } from '../../../view/design/icon/HumanFactorLogo';
import { StartIcon } from '../../../view/design/icon/StartIcon';

export const ASMBottomMenuLogo = () => {
  const { getCurrentLanguage } = useI18n();

  return (
    <Box sx={{ padding: '18px 21px 18px 16px', display: 'flex', width: 'auto', gap: 2 }}>
      {getCurrentLanguage() === 'ru' ? <StartIcon /> : <HumanFactorLogo />}
      {getCurrentLanguage() === 'ru' ? <EDUIcon /> : <AppSecLogo />}
    </Box>
  );
};