import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';
import { ButtonBase, SxProps, Typography } from '@mui/material';
import React from 'react';

const MID_BUTTON_SX: SxProps = {
  padding: '10px 23px',
  borderRadius: '10px',
  background: 'rgba(255,255,255,.03)',
  fontSize: 'inherit',
  lineHeight: 'inherit',
  height: '40px'
};

const ARROW_BUTTON_SX: SxProps = {
  borderRadius: '10px',
  background: 'rgba(255,255,255,.03)',
  padding: '10px',
  height: '40px'
}

type IPagination = {
  currentPage: number;
  totalPage: number;
  onChangePage: (newPage: number) => void;
}
export const Pagination = React.memo(({ currentPage, totalPage, onChangePage }: IPagination) => {
  const currentPageRef = React.useRef(currentPage)
  currentPageRef.current = currentPage;

  const handlePrevPage = React.useCallback(() => onChangePage(currentPageRef.current - 1), [onChangePage, currentPageRef])
  const handleNextPage = React.useCallback(() => onChangePage(currentPageRef.current + 1), [onChangePage, currentPageRef])

  if (totalPage === 0) return null;

  const middleLabel = `${currentPage}/${totalPage}`

  return <Typography
    variant="body2"
    sx={{
      display: 'flex',
      gap: '8px',
      flexDirection: 'row',
    }}>
    <ButtonBase sx={ARROW_BUTTON_SX} color="secondary" onClick={handlePrevPage} disabled={currentPageRef.current === 1} ><KeyboardArrowLeftIcon /></ButtonBase>
    <ButtonBase sx={MID_BUTTON_SX} color="secondary">{middleLabel}</ButtonBase>
    <ButtonBase sx={ARROW_BUTTON_SX} color="secondary" onClick={handleNextPage} disabled={currentPageRef.current === totalPage} ><KeyboardArrowLeftIcon sx={{ transform: 'rotate(180deg)' }} /></ButtonBase>
  </Typography>
})