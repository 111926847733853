import { Box } from "@mui/material";
import { observer } from 'mobx-react';
import React from "react";
import { BigCTFLink } from "../parts/BigCTFLinks";
import { CTFRatingBlock } from "../parts/CTFRatingBlock";
import { IRatingDomain } from "../parts/CTFRatingBlock/store/IRatingDomain";
import { CTFVideoBlock } from "../parts/CTFVideoBlock";

type ICTFRatingPage = {
  raingDomain: IRatingDomain;
}

export const CTFRatingPage = observer(({ raingDomain }: ICTFRatingPage) => (
  <Box sx={{
    display: 'flex',
    gap: '24px',
    flexDirection: 'column',
  }}>
    <BigCTFLink />
    <CTFVideoBlock />
    <CTFRatingBlock raingDomain={raingDomain} />
  </Box>
))