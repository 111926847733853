
import { inject } from "../../../../../../../common/container/inject";
import { ILearningRootService, LearningRootServiceToken } from "../../../../../service/LearningRootService";
import { IDashboardDomain } from "./IDashboardDomain";

export class DashboardDomain implements IDashboardDomain {
  constructor(
    protected learningRootService = inject<ILearningRootService>(LearningRootServiceToken)
  ) {}

  getDashboardData = () => this.learningRootService.report.getProjectStat({});
}