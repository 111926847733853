import { ApplicationRouterExtensions } from '../../application/ApplicationRouterExtensions';
import { IContainer } from "../../common/container/IContainer";
import { AdditionalNavItemToken } from "../../view/layout/main/parts/LeftNavigation/container/AdditionalNavItem/AdditionalNavItemToken";
import { IAdditionalNavItem } from "../../view/layout/main/parts/LeftNavigation/container/AdditionalNavItem/IAdditionalNavItem";
import { IExtension } from "../interface/IExtension";
import { CTFConfigModule } from "./service/config/CTFConfigModule";
import { CTFRouter } from './service/router/CTFRouter';
import { CTFNavItemLink } from "./view/design/list/CTFNavItemLink";

export default class CTFExtension implements IExtension {
  async loadExtension(container: IContainer): Promise<void> {
    try {
      await this.loadConfig();
      this.addItemToNav(container);
      this.addRouter(container);
    } catch {
    }
  }

  protected async loadConfig() {
    await CTFConfigModule.loadConfigFromBackend()
  }

  protected addItemToNav(container: IContainer): void {
    const prev: IAdditionalNavItem[] = container.getByToken(AdditionalNavItemToken);
    prev.push({ Component: CTFNavItemLink, key: 'ctf' })
  }

  protected addRouter(container: IContainer) {
    let routerExtension = container.get(ApplicationRouterExtensions);
    routerExtension.push(...CTFRouter);
    container.set(ApplicationRouterExtensions, routerExtension);
  }

}
