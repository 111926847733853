import React, { ReactEventHandler } from 'react';

type IImgProps = {
  fallbackSrc?: string;
} & React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>;

export const Img = React.memo(({ fallbackSrc, ...props }: IImgProps) => {
  const { src } = props
  const changeImgForLocal: ReactEventHandler<HTMLImageElement> = React.useCallback(({ currentTarget }) => {
    currentTarget.onerror = null;
    currentTarget.src = fallbackSrc ?? `/public${src}`;

  }, [fallbackSrc, src]);

  return <React.Fragment>
    <img {...props} onError={changeImgForLocal} />
  </React.Fragment>
})