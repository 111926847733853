import { CoreRestCRUDService } from '../../../../../service/coreCommon/common/rest/CoreRestCRUDService';
import { CRUDServiceType } from '../../../../../service/common/service/CRUDServiceType';
import { IProjectModel } from '../../../../../service/project/IProjectModel';
import { IProjectSearchService } from './IProjectSearchService';
import { ISearchRequestData } from '../../../../../service/common/search/ISearchRequestData';

export class RestProjectSearchService extends CoreRestCRUDService<IProjectModel> implements IProjectSearchService {
  constructor(private type: CRUDServiceType) {
    super();
  }

  getEndpoint(): string {
    const url = this.config.url.api[this.type];
    return `${url}/gpb/search`;
  }

  async searchGPBProjects(searchRequest: ISearchRequestData) {
    const result = await this.request()
    .url(this.getEndpoint())
    .post(searchRequest)
    .json()
    .catch(this.handleError);
    return result;
  }
}
