import { IProjectLearningReportModel } from '../../../../service/report/interface/IProjectLearningReportModel';

const calcPercent = ({
  total,
  count,
}: {
  total: number,
  count: number
}) => total === 0 ? 0 : Math.round((count * 100) / total);

export const mapToDashboardData = (projectsWithUserStats: IProjectLearningReportModel[]) => {
  const dashboardData = projectsWithUserStats.map((item) => {
    const total =
      item.stats.usersNotPassedStudying + item.stats.usersPassedStudying + item.stats.usersNotStartedStudying;

      return {
        name: `${item.project.name}`,
        uvCount: item.stats.usersNotPassedStudying,
        pvCount: item.stats.usersPassedStudying,
        dvCount: item.stats.usersNotStartedStudying,
        uv: calcPercent({ total, count: item.stats.usersNotPassedStudying }),
        pv: calcPercent({ total, count: item.stats.usersPassedStudying }),
        dv: calcPercent({ total, count: item.stats.usersNotStartedStudying }),
    };
  });

  return dashboardData;
};
