import { IRestCTFStatisticService } from "../../../../../../service/statistc/IRestCTFStatisticService";
import { RestCTFStatisticService } from "../../../../../../service/statistc/RestCTFStatisticService";
import { ICountsSolvedDomain } from "./ICountsSolvedDomain";

export class CountsSolvedDomain implements ICountsSolvedDomain  {
  private restApi: IRestCTFStatisticService;

  constructor() {
    this.restApi = new RestCTFStatisticService();
  }

  getData = () => this.restApi.getCorrectIncorrect()
}