import { Box, Button, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { memo } from 'react';
import { Img } from '../../../../design/img/Img';
import { CTFLinksUI } from './store/CTFLinksUI';
import { CTFLinkButtonContent } from './store/ICTFLinksUI';

const useClasses = makeStyles(() => ({
  img: {
    objectFit: 'cover',
    opacity: '.5',
    width: '100%',
    height: '100%'
  }
}))
const LinkBigButton = memo(({ Icon, link, label, img, fallbackImg }: CTFLinkButtonContent) => {
  const { img: imgClass } = useClasses()
  const linkLabel = React.useMemo(() => {
    if (!link) return '';

    const { protocol, hostname } = new URL(link);

    return `${protocol}//${hostname}/`.toLowerCase()
  }, [link]);

  if (!link) return null;

  return (<Link href={link} target="_blank" rel="noopener noreferrer" width='100%'>
    <Button variant='text' color='secondary' sx={{
      width: '100%',
      display: 'inline-flex',
      height: '96px',
      justifyContent: 'space-between',
      padding: 0,
      overflow: 'hidden',
      border: '1px solid rgba(255, 255, 255, 0.18)',
      borderRadius: '8px',
    }}>
      <Box sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', padding: '16px 8px 16px 24px' }}>
        <Typography variant='h5' sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
          <Icon /> {label}
        </Typography>
        <Typography variant='body1' sx={{
          color: 'rgba(255, 255, 255, 0.7)',
          textTransform: 'lowercase',
        }}>
          {linkLabel}
        </Typography>
      </Box>
      <Box sx={{
        width: '240px',
        height: '100%'
      }}>
        <Img src={img} fallbackSrc={fallbackImg} className={imgClass} />
      </Box>
    </Button>

  </Link>)
})

export const BigCTFLink = () => {
  const [{ btnsContent }] = React.useState(new CTFLinksUI());

  return (
    <Box sx={{
      display: 'flex',
      gap: '16px',
    }}>
      {btnsContent.map(content => <LinkBigButton {...content} key={content.link} />)}
    </Box>
  )
}