import { Box } from '@mui/material';
import React, { memo } from 'react';
import { ChallengesByGroup } from '../parts/ChallengesByGroup';
import { CommonStatistic } from '../parts/CommonStatistic';
import { CountsSolved } from '../parts/CountsSolved';
import { UsersResultsGraph } from '../parts/UsersResultsGraph';

export const StatisticCTFPage = memo(() => <Box
  sx={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
  <CommonStatistic />
  <UsersResultsGraph />
  <Box sx={{
    display: 'flex',
    flexDirection: 'row',
    gap: '8px',
    width: '100%',

  }}>
    <Box sx={{
      flex: 1
    }}>
      <CountsSolved />
    </Box>
    <Box sx={{
      flex: 1
    }}>
      <ChallengesByGroup />
    </Box>
  </Box>
</Box>)
