import React, { useContext, useState } from 'react';
import { Search } from '@mui/icons-material';
import { InputAdornment, TextField, Theme } from '@mui/material';
import { observer } from 'mobx-react';
import { makeStyles } from '@mui/styles';

import { ProjectListDomain } from '../../../../view/user/page/project/list/store/ProjectListDomain';
import { FilterComponent } from '../../../../view/user/page/project/list/inject/FilterComponent';
import { useI18n } from '../../../../application/language/useI18n';
import { ContainerContext } from '../../../../common/container/ContainerContext';
import { IProjectSearchDomainExtended } from './store/IProjectSearchDomainExtended';

interface IProjectSearchBarProps {
  domain: ProjectListDomain;
}

export const ProjectSearchBarExtended = observer(({ domain }: IProjectSearchBarProps) => {
  const { translate } = useI18n()
  const container = useContext(ContainerContext);
  const extendedFilterComponent = container.get(FilterComponent);
  const classes = useStyles();
  const extendedDomain = container.get(domain) as IProjectSearchDomainExtended;
  return (
    <div className={classes.searchBlock}>
      <TextField
        className={classes.searchInput}
        size="small"
        variant="outlined"
        maxRows={1}
        autoComplete={'off'}
        value={extendedDomain.ui.searchProjectTerm.value}
        onChange={(event) => {
          extendedDomain.ui.searchProjectTerm.setValue(event?.target?.value || '');
          extendedDomain.ui.projectListOffset.setValue(0);
          extendedDomain.triggerSearchGPB(0);
        }}
        placeholder={translate('phrases.searchTable')}
        InputProps={{
          startAdornment: (
            <InputAdornment position='start'>
              <Search />
            </InputAdornment>
          ),
          style: { fontSize: 16, paddingLeft: 10 }
        }}
        InputLabelProps={{ style: { fontSize: 13 } }}
      />
      {extendedFilterComponent.map((Component, index) => {
        return <Component.Component key={index} domain={domain} />
      })}
    </div>
  )
})


export const useStyles = makeStyles((theme: Theme) => ({
  searchBlock: {
    width: '100%',
    marginRight: 'auto',
    margin: 0,
    fontSize: '13px',
    marginTop: 15,
    display: 'flex',
  },
  managerFilter: {
    width: '20%',
    minWidth: '200px',
    fontSize: '13px',
  },
  searchInput: {
    marginRight: 10,
    width: "100%"
  },
}));

