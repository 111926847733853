import { IContainer } from '../common/container/IContainer';
import { IExtension } from './interface/IExtension';

export const loadExtension = async (extensionsNames: string[], container: IContainer) => {
  extensionsNames = extensionsNames ? [...extensionsNames, 'logger'] : ['logger'];
  return Promise.all(extensionsNames
    .map(async (extensionName) => {
      try {
        const extensionType = require(`./${extensionName}/index`).default;
        if (extensionType) {
          const extension: IExtension = new extensionType();
          return extension.loadExtension(container);
        }
        return null;
      } catch (error) {}
    })).then(result => result.filter((extension) => extension !== null))

};