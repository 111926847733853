import { injectEntityList } from "../../../../../../../../common/store/base/injectEntityList";
import { injectPrimitive } from "../../../../../../../../common/store/base/injectPrimitive";
import { IEntityListStore } from "../../../../../../../../common/store/interface/IEntityListStore";
import { IPrimitiveStore } from "../../../../../../../../common/store/interface/IPrimitiveStore";
import { CTFConfigModule } from "../../../../../../service/config/CTFConfigModule";
import { IMostAndLessPopularChallenges } from "../../../../../../service/statistc/interface/IMostAndLessPopularChallenges";
import { ICommonStatisticDomain } from "./ICommonStatisticDomain";
import { ICommonStatisticUI } from "./ICommonStatisticUI";
import { IStatisticBlock } from "./IStatisticBlock";

const getUserWordForm = (number: number): string =>  {
    const lastDigit = number % 10;
    const lastTwoDigits = number % 100;

    if (lastTwoDigits >= 11 && lastTwoDigits <= 14) {
        return 'пользователей';
    }

    if (lastDigit === 1) {
        return 'пользователь';
    }

    if (lastDigit >= 2 && lastDigit <= 4) {
        return 'пользователя';
    }

    return 'пользователей';
}

const getDataRuLabel = (dateNumber: number): string => {
  const date = new Date(dateNumber);

  return date.toLocaleDateString('ru-RU', {
    day: 'numeric',
    month: 'long',
    year: 'numeric'
  }).slice(0, -2);

}


export class CommonStatisticUI implements ICommonStatisticUI {
  private alreadyBooted: IPrimitiveStore<boolean>;
  blocksContent: IEntityListStore<IStatisticBlock>;
  isFetching: IPrimitiveStore<boolean>;

  constructor(
    private commonStatisticDomain: ICommonStatisticDomain,
  ) {
    this.alreadyBooted = injectPrimitive(false);
    this.blocksContent = injectEntityList<IStatisticBlock>([]);
    this.isFetching = injectPrimitive(false);
  }


  loadData = async () =>  {
    if (this.alreadyBooted.value) return;
    this.isFetching.setValue(true);

    let userCount: null | number = null, mostLessPopular: null | IMostAndLessPopularChallenges = null;

    await Promise.allSettled([
      this.commonStatisticDomain.loadUserCount().then(count => userCount = count),
      this.commonStatisticDomain.loadMostAndLessPopularChallenges().then(data => mostLessPopular = data)
    ])

    const newBlocks: IStatisticBlock[] = [];

    this.addUserCountBlock(newBlocks, userCount);
    this.addLessAndPopularChallengersBlocl(newBlocks, mostLessPopular);

    this.alreadyBooted.setValue(true)
    this.blocksContent.setList(newBlocks);
    this.isFetching.setValue(false);
  }

  protected addUserCountBlock = (blocks: IStatisticBlock[], count: number | null): IStatisticBlock[] => {
    if (count === null) return blocks;

    blocks.push({
      label: getUserWordForm(count),
      count,
      title: `Проект стартовал ${getDataRuLabel(CTFConfigModule.serverConfig.ctfDateStart)}`
    });

    return blocks;
  }

  protected  addLessAndPopularChallengersBlocl = (blocks: IStatisticBlock[], mostLessPopular: null | IMostAndLessPopularChallenges) => {
    if (mostLessPopular === null) return blocks;

    const { most, less } = mostLessPopular;

    if (most) {
      blocks.push({
        title: 'Решают чаще всего',
        count: most.solves,
        label: `«${most.name}»`
      });
    }

    if (less) {
        blocks.push({
        title: 'Решают реже всего',
        count: less.solves,
        label: `«${less.name}»`
      });
    }

    return blocks;
  }
}