import wretch from 'wretch';

import { CTFCoreRestCRUDService } from '../common/CTFCoreRestCRUDService';
import { ICTFUserResult } from './interface/ICTFUserResult';
import { IRestRatingService } from './IRestRatingService';

wretch().errorType('json');

export class RestRatingService extends CTFCoreRestCRUDService<ICTFUserResult> implements IRestRatingService {
  getEndpoint(): string {
    const url = this.getBaseAPIUrl()
    return `${url}rating`;
  }

  async getUsersScoreByGroup() {
    const searchResult = await this.request()
      .url(`${this.getEndpoint()}/getUsersScoreByGroup`)
      .get()
      .res()
      .catch(this.handleError);

    const searchResultText = await searchResult.text();

    return JSON.parse(searchResultText, this.dateTimeReviver);
  }
}
