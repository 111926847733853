import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

import { CTFConfigModule } from "../../../../../../service/config/CTFConfigModule";
import { CTFLinkButtonContent, ICTFLinksUI } from "./ICTFLinksUI";

export class CTFLinksUI implements ICTFLinksUI {
  btnsContent: CTFLinkButtonContent[];

  constructor() {
    this.createLinks();
  }

  private createLinks() {
    const { ctfCTFdLink, ctfApplicationLink } = CTFConfigModule.serverConfig;
    const ctfdContent: CTFLinkButtonContent = {
      Icon: PlayArrowOutlinedIcon,
      label: 'уязвимое приложение'.toUpperCase(),
      link: ctfApplicationLink,
      img: '/public/extension/ctf/appPreview.jpg',
      fallbackImg: '/extension/ctf/appPreview.jpg',
    };

    const ctfAppContent: CTFLinkButtonContent = {
      Icon: SettingsIcon,
      label: 'CTF'.toUpperCase(),
      link: ctfCTFdLink,
      img: '/public/extension/ctf/ctfDPreview.jpg',
      fallbackImg: '/extension/ctf/ctfDPreview.jpg',
    };

    this.btnsContent = [ctfdContent, ctfAppContent]
  }
}