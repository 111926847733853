import PlayArrowOutlinedIcon from '@mui/icons-material/PlayArrowOutlined';
import SettingsIcon from '@mui/icons-material/Settings';

import { CTFConfigModule } from "../../../../../../service/config/CTFConfigModule";
import { CTFLinkButtonContent, ICTFLinksUI } from "./ICTFLinksUI";

export class CTFLinksUI implements ICTFLinksUI {
  btnsContent: CTFLinkButtonContent[] = [];

  constructor() {
    this.createLinks();
  }

  private createLinks() {
    const { ctfCTFdLink, ctfApplicationLink } = CTFConfigModule.serverConfig;
    const ctfdContent: CTFLinkButtonContent = {
      Icon: PlayArrowOutlinedIcon,
      label: 'уязвимое приложение'.toUpperCase(),
      link: ctfApplicationLink
    };

    const ctfAppContent: CTFLinkButtonContent = {
      Icon: SettingsIcon,
      label: 'Панель управления CTF'.toUpperCase(),
      link: ctfCTFdLink,
    };

    this.btnsContent = [ctfAppContent, ctfdContent]

  }
}